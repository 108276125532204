import { Box } from '@mui/material';
import dayjs from 'dayjs';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Rectangle,
  ReferenceLine,
} from 'recharts';
import { FlexBox } from 'components/Containers/FlexBox';
import { ThemePalette } from 'mui.theme';
import type { DeviceStats } from 'types/utilization.types';
import { calculateUtilization, getDataUnit } from 'views/Utilization/utils';
import { convertNanosecondsToMinutes } from 'views/Production/Hauler/component/utils';
import { truncateTo2dp } from 'utils/helpers/general';

interface Props {
  enableDarkTheme: boolean;
  selectedSource: string;
  unitSystem: string;
  height?: string;
  data: DeviceStats;
}

const AssetItemChart = ({
  enableDarkTheme,
  selectedSource,
  unitSystem,
  height = '230px',
  data,
}: Props) => {
  let dataUnit = getDataUnit(selectedSource, unitSystem);
  dataUnit = dataUnit ? `(${dataUnit})` : '';
  const formatTimestamp = (timestamp: number): string => {
    const date = dayjs(timestamp);
    const formattedDate = date.format('DD MMM');
    return formattedDate;
  };

  const { date_stats: dateStats } = data;

  const formattedDateLoad: any[] = Object.keys(dateStats).map((date) => ({
    date,
    ...dateStats[date],
    idling: truncateTo2dp(
      convertNanosecondsToMinutes(Number(dateStats[date].idling_duration))
    ),
    utilization: truncateTo2dp(
      calculateUtilization(
        Number(10.5 * 60),
        Number(dateStats[date].utilization_engine_working_minute)
      )
    ),
    fuel: truncateTo2dp(dateStats[date].fuel_consumption_l),

    production: truncateTo2dp(dateStats[date].max_load_ton),
  }));

  const validEntries = formattedDateLoad.filter(
    (entry: any) => Number(entry[selectedSource.toLowerCase()]) !== 0
  );

  const average =
    validEntries.reduce(
      (sum: number, entry: any) =>
        sum + Number(entry[selectedSource.toLowerCase()]),
      0
    ) / validEntries.length;

  return (
    <Box sx={{ paddingTop: '10px' }}>
      <FlexBox
        sx={{
          maxWidth: 'inherit',
          height,
          paddingTop: '20px',
          marginRight: '10px',
          paddingBottom: '10px',
        }}
      >
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={700}
            height={300}
            data={formattedDateLoad}
            margin={{
              top: 20,
              right: 100,
              left: 5,
              bottom: 5,
            }}
            barSize={30}
          >
            <CartesianGrid
              strokeDasharray="3 3"
              stroke={ThemePalette.dark.cartesianGrid}
              vertical={false}
            />
            <XAxis
              dataKey="date"
              tickFormatter={formatTimestamp}
              tick={{ fontSize: 10 }}
            />
            <YAxis
              tick={{ fontSize: 10 }}
              label={{
                value: `${selectedSource} ${dataUnit}`,
                angle: 270,
                position: 'insideLeft',
                fontSize: 10,
                textAnchor: 'middle',
                dy: 50,
              }}
            />
            <Tooltip
              cursor={{ fill: '#1B2B4A' }}
              wrapperStyle={{ outline: 'none', fontSize: '11px' }}
              contentStyle={{
                backgroundColor: 'transparent',
              }}
              itemStyle={{
                color: enableDarkTheme
                  ? ThemePalette.typography.wheat
                  : ThemePalette.typography.black,
              }}
            />
            <Bar
              dataKey={selectedSource.toLowerCase()}
              fill="#4A7BFA"
              activeBar={<Rectangle fill="pink" stroke="blue" />}
            />
            <ReferenceLine
              y={average}
              label={{
                value: `Avg ${average.toFixed(2)} ${dataUnit}`,
                position: 'right',
                fontSize: '8px',
              }}
              stroke="#4A7BFA"
              strokeDasharray="3 3"
              isFront
            />
          </BarChart>
        </ResponsiveContainer>
      </FlexBox>
    </Box>
  );
};

export default AssetItemChart;
