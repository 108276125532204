import CustomerAPI from 'api/customer.api';
import { updateLoadingState } from 'store/app.slice';
import {
  type IUpdateCustomerPayload,
  type ICreateCustomerPayload,
} from 'types/payloads/customer.payload.types';
import { BaseHandler } from './base.handler';
import { getAll, appendCustomer } from 'store/customer.slice';
import { type Customer } from 'types/customer.types';

export default class CustomerHandler extends BaseHandler {
  private readonly api: CustomerAPI;
  constructor() {
    super();

    this.api = new CustomerAPI();
  }

  // create user using api
  async createCustomer(payload: ICreateCustomerPayload): Promise<void> {
    this.dispatch(updateLoadingState(true));

    try {
      const newCustomer = await this.api.createCustomer(payload);

      this.dispatch(appendCustomer(newCustomer));

      this.handleSuccess(
        `New Account '${payload.customerName}' has been created.`
      );
    } catch (_) {
      // show snackbar error
      this.handleError('An error occurred while creating the customer.');
    }
  }

  // get customers using api
  async getAll(): Promise<void> {
    this.dispatch(updateLoadingState(true));

    try {
      const { customers } = await this.api.getAll();

      this.dispatch(getAll(customers));
      this.dispatch(updateLoadingState(false));
    } catch (_) {
      // show snackbar error
      this.dispatch(updateLoadingState(false));
    }
  }

  async updateCustomer(payload: IUpdateCustomerPayload) {
    this.dispatch(updateLoadingState(true));
    try {
      const customer = await this.api.updateCustomer(payload);
      this.dispatch();
    } catch (_) {
      this.dispatch(updateLoadingState(false));
    }
  }

  async updateCustomerTheme(payload: { theme: string; customerId: string }) {
    this.dispatch(updateLoadingState(true));
    try {
      const customer = await this.api.updateCustomerTheme(payload);
      this.dispatch();
    } catch (_) {
      this.dispatch(updateLoadingState(false));
    }
  }
}
