import { Grid } from '@mui/material';
import CardAnalytics from 'views/Production/components/CardAnalytics/CardAnalytics';
import type {
  utilizationFleetScore,
  FleetSummary,
  chartDataResponseItem,
} from 'types/utilization.types';
import { convertNanosecondsToMinutes } from 'views/Utilization/utils';
import { truncateTo2dp } from 'utils/helpers/general';
import {
  evaluateNumber,
  numberEvaluationKeyMappings,
  getFleetKpiThreshold,
} from 'views/FleetOverview/components/AssetCardsV2/utils';

interface Props {
  enableDarkTheme: boolean;
  isLoading: boolean;
  unitSystem: string;
  assetOverview: any;
  summary: FleetSummary;
  score: utilizationFleetScore;
  data: chartDataResponseItem[];
}

const GridAnalytics = ({
  enableDarkTheme,
  isLoading,
  assetOverview,
  summary,
  score,
  data,
}: Props) => {
  const isNegative = (value: number) => value < 0;

  const returnAnalysis = (value: number | string) => {
    return `${Math.abs(isNaN(Number(value)) ? 0 : Number(value))}% ${
      isNegative(Number(value)) ? 'less' : 'more'
    }`;
  };

  const kpiThresholds = getFleetKpiThreshold(assetOverview.kpiThresholds);
  const thresholdMappings = numberEvaluationKeyMappings('total');
  const productionValue = evaluateNumber(
    score.util_score,
    thresholdMappings.utilization,
    kpiThresholds
  );

  const utilizationChart = data.map((item) => {
    return {
      name: item.date,
      value:
        (item.sum_utilization_engine_working_minute / item.timeRangeMinutes) *
        100,
    };
  });

  const shiftHoursChart = data.map((item) => {
    return {
      name: item.date,
      value: item.timeRangeMinutes,
    };
  });

  const idlingHoursChart = data.map((item) => {
    return {
      name: item.date,
      value: item.sum_idling_duration,
    };
  });

  const engineHoursChart = data.map((item) => {
    return {
      name: item.date,
      value: item.sum_utilization_engine_working_minute,
    };
  });

  const tr = [
    {
      title: 'Utilization',
      pointingUp: !isNegative(summary.utilizationDiff),
      value: truncateTo2dp(summary.utilization),
      unit: '%',
      isGreen: !isNegative(summary.utilizationDiff),
      analysis: returnAnalysis(summary.utilizationDiff),
      message: 'from last period',
      hasWarning: false,
      warningCount: 0,
      showProgressBar: true,
      progressBarValue: productionValue,
      chartData: utilizationChart,
    },
    {
      title: 'Avg Shift Hours',
      pointingUp: !(summary.shiftHoursDiff < 0),
      value: truncateTo2dp(summary.shiftHours / 60),
      unit: 'Hrs',
      isGreen: !(summary.shiftHoursDiff < 0),
      analysis: returnAnalysis(truncateTo2dp(summary.shiftHoursDiff)),
      message: 'from last period',
      hasWarning: false,
      warningCount: 0,
      showProgressBar: false,
      progressBarValue: 78,
      chartData: shiftHoursChart,
    },
    {
      title: 'Avg Idling Hours',
      pointingUp: !(summary.idling < 0),
      value: truncateTo2dp(convertNanosecondsToMinutes(summary.idling) / 60),
      unit: 'Hrs',
      isGreen: !(summary.idlingDiff < 0),
      analysis: returnAnalysis(truncateTo2dp(summary.idlingDiff)),
      message: 'from last period',
      hasWarning: false,
      warningCount: 0,
      showProgressBar: false,
      progressBarValue: 78,
      chartData: idlingHoursChart,
    },
    {
      title: 'Avg Engine Hours',
      pointingUp: !(summary.engineHoursDiff < 0),
      value: truncateTo2dp(summary.engineHours / 60),
      unit: 'Hrs',
      isGreen: !(summary.engineHoursDiff < 0),
      analysis: returnAnalysis(truncateTo2dp(summary.engineHoursDiff)),
      message: 'from last period',
      hasWarning: false,
      warningCount: 0,
      showProgressBar: false,
      progressBarValue: 78,
      chartData: engineHoursChart,
    },
  ];

  return (
    <Grid
      container
      spacing={2}
      justifyContent="space-between"
      sx={{
        paddingTop: '15px',
      }}
    >
      {tr.map((item, index) => {
        return (
          <Grid item key={index} lg={3} md={6} sm={12} xs={12}>
            <CardAnalytics
              enableDarkTheme={enableDarkTheme}
              analytics={item}
              isLoading={isLoading}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default GridAnalytics;
