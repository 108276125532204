import { type Asset } from 'types/asset.types';
import {
  type EolDates,
  type AssetDetail,
  type AssetsOverview,
  type ServiceView,
  type CumulativeOverview,
} from 'types/survivalAnalysis.types';

export const getUniqueAssetsFromComponent = (
  uniqueAssets: ServiceView[],
  selectedComponent: string,
  selectedSubComponents?: string
) => {
  // Filter assets based on the selected component
  let updatedAssets = uniqueAssets.filter((component) =>
    component.components_category.includes(selectedComponent)
  );

  // If selectedSubComponents exists, further filter the assets
  if (selectedSubComponents) {
    updatedAssets = updatedAssets.filter((component) =>
      component.sub_component.includes(selectedSubComponents)
    );
  }

  // Ensure that there's at least one matching component
  if (updatedAssets.length > 0) {
    return updatedAssets[0].componentsCategoryAssets;
  } else {
    return []; // or return any default value you prefer
  }
};

export const processDataComponents = (
  items: AssetsOverview[],
  assets: Asset[],
  componentFinancialMetricData: any[],
  eolDates: EolDates[]
): ServiceView[] => {
  const assetMap = assets.reduce<Record<string, Asset>>((acc, asset) => {
    acc[asset.bumperNumber] = asset;
    return acc;
  }, {});

  const result = items.reduce<Record<string, ServiceView>>((acc, item) => {
    const assetId = item.asset_id;
    const asset = assetMap[assetId];

    if (!asset) {
      return acc;
    }

    const componentKey = item.components_category ?? 'unknown_component';
    const subComponentKey = item.sub_component ?? 'unknown_sub_component';
    const itemKey = `${componentKey} - ${subComponentKey}`;

    if (!acc[itemKey]) {
      acc[itemKey] = {
        item_id: itemKey,
        assetId: assetId,
        high: 0,
        med: 0,
        low: 0,
        componentsCategories: new Set<string>(),
        subComponentCategories: new Set<string>(),
        componentsCategoryAssets: new Set<AssetDetail>(),
        date_of_orders: '',
        work_order_age_in_days: item.work_order_age_in_days,
        work_order_age_category: item.work_order_age_category,
        components_category: componentKey,
        sub_component: subComponentKey,
        type: '',
        oem: '',
        model: '',
        lastConnected: '',
      };
    }

    const category = item.work_order_age_category;

    // Increment counters based on category and EOL existence
    if (category === 'low') {
      acc[itemKey].low = Number(acc[itemKey].low ?? 0) + 1;
    } else if (category === 'med') {
      acc[itemKey].med = Number(acc[itemKey].med ?? 0) + 1;
    } else if (category === 'high' || category === 'eol') {
      acc[itemKey].high = Number(acc[itemKey].high ?? 0) + 1;
    }

    const assetDetails: AssetDetail = {
      asset_id: assetId,
      date_of_orders: item.date_of_orders,
      work_order_age_in_days: item.work_order_age_in_days,
      work_order_age_category: item.work_order_age_category,
      components_category: item.components_category,
      sub_component: item.sub_component,
    };

    acc[itemKey].componentsCategoryAssets.add(assetDetails);

    if (item.components_category) {
      acc[itemKey].componentsCategories.add(item.components_category);
    }
    if (item.sub_component) {
      acc[itemKey].subComponentCategories.add(item.sub_component);
    }

    return acc;
  }, {});

  const financialMetricsMap: any = Object.entries(
    componentFinancialMetricData
  ).reduce((acc: any, [key, value]: [string, any]) => {
    acc[key] = value.total_cost;
    return acc;
  }, {});

  const updatedAssets = Object.values(result).map((asset: any) => {
    const category = asset.components_category;
    const cost = financialMetricsMap[category] || 0;
    return {
      ...asset,
      cost,
    };
  });

  return Object.values(updatedAssets);
};

export const getUniqueValues = (data: CumulativeOverview[]) => {
  const uniqueComponents = new Set<string>();
  const uniqueSubComponents = new Set<string>();

  data.forEach((item) => {
    uniqueComponents.add(item.components_category);
    uniqueSubComponents.add(item.sub_component_categories);
  });

  return {
    componentsCategories: Array.from(uniqueComponents),
    subComponentCategories: Array.from(uniqueSubComponents),
  };
};
