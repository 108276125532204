import { type SyntheticEvent } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useAppSelector } from 'store/hook';
import { isDarkTheme } from 'utils/theme';
import { ThemePalette } from 'mui.theme';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface Props {
  value: number;
  handleChange: (newValue: number) => void;
  disableLoader?: boolean;
}

const HeaderMenu = ({ value, handleChange, disableLoader = true }: Props) => {
  const { theme } = useAppSelector((state) => state.authReducer).customer;
  const enableDarkTheme = isDarkTheme(theme);

  const setHandleChange = (event: SyntheticEvent, newValue: number) => {
    handleChange(newValue);
  };

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs
        value={value}
        onChange={setHandleChange}
        aria-label="basic tabs"
        TabIndicatorProps={{
          sx: {
            backgroundColor: '#5E85F0',
          },
        }}
      >
        <Tab
          label="Total Fleet"
          {...a11yProps(0)}
          style={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
            textTransform: 'capitalize',
            fontWeight: 'bold',
          }}
        />
        <Tab
          label="Haulers"
          {...a11yProps(1)}
          style={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
            textTransform: 'capitalize',
            fontWeight: 'bold',
          }}
        />
        <Tab
          label="Loaders"
          {...a11yProps(2)}
          style={{
            color: disableLoader
              ? ThemePalette.typography.lightGrey
              : enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
            textTransform: 'capitalize',
            fontWeight: 'bold',
          }}
          disabled={disableLoader}
        />
      </Tabs>
    </Box>
  );
};

export default HeaderMenu;
