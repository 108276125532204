import { Grid } from '@mui/material';
import { ThemePalette } from 'mui.theme';
import Details from './Details';
import Chart from './Chart';
import type {
  utilizationLoadingStates,
  FleetDetailsItem,
} from 'types/utilization.types';

interface Props {
  enableDarkTheme: boolean;
  unitSystem: string;
  loadingStates: utilizationLoadingStates;
  chartData: any[];
  detailsData: FleetDetailsItem;
}

const ProductiveHours = ({
  enableDarkTheme,
  unitSystem,
  chartData,
  loadingStates,
  detailsData,
}: Props) => {
  return (
    <Grid
      container
      spacing={2}
      justifyContent="space-between"
      sx={{
        color: enableDarkTheme
          ? ThemePalette.typography.white
          : ThemePalette.typography.black,
      }}
    >
      <Details
        enableDarkTheme={enableDarkTheme}
        unitSystem={unitSystem}
        isLoading={loadingStates.isLoadingFleetDetails}
        data={detailsData}
      />
      <Chart
        enableDarkTheme={enableDarkTheme}
        unitSystem={unitSystem}
        isLoading={loadingStates.isLoadingFleetPeriodSummary}
        data={chartData}
      />
    </Grid>
  );
};

export default ProductiveHours;
