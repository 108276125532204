import { Grid } from '@mui/material';
import { ThemePalette } from 'mui.theme';
import { CustomTabPanel } from 'views/Production/CustomPanel/index';
import ProductiveHours from '../components/ProductiveHours';
import DateRangeDiv from 'views/Production/HeaderMenu/DateRangeDiv';
import GridAnalytics from '../components/GridAnalytics';
import HoursBreakdown from '../components/HoursBreakdown';
import FuelConsumption from '../components/FuelConsumption';
import Suggestions from 'views/Production/components/Suggestions/Suggestions';
import AssetView from '../components/Asset/AssetWrapper';
import type {
  utilizationLoadingStates,
  utilizationFleetData,
} from 'types/utilization.types';

interface Props {
  value: number;
  enableDarkTheme: boolean;
  isSelected: string;
  setIsSelected: any;
  handleDateChange: any;
  startDate: any;
  endDate: any;
  yesterday: any;
  unitSystem: string;
  assetOverview: any;
  loadingStates: utilizationLoadingStates;
  data: utilizationFleetData;
}

const Loader = ({
  value,
  enableDarkTheme,
  unitSystem,
  isSelected,
  setIsSelected,
  handleDateChange,
  startDate,
  endDate,
  yesterday,
  assetOverview,
  loadingStates,
  data,
}: Props) => {
  const summaryData = {
    utilization:
      (data.summary.loader_avg_utilization_engine_working_minute / 630) * 100,
    utilizationDiff: 0,
    idling: data.summary.loader_avg_idling_duration,
    idlingDiff: data.summary.loader_avg_idling_duration_diff,
    shiftHours: 630, // 10.5 in mins,
    shiftHoursDiff: 0,
    engineHours: data.summary.loader_avg_utilization_engine_working_minute,
    engineHoursDiff:
      data.summary.loader_avg_utilization_engine_working_minute_diff,
  };

  const loaderBreakDown = {
    productiveHours: data.summary.loaderSumWorkingMinutes,
    productiveHoursDiff: data.summary.loaderSumWorkingMinutes_diff,
    idling: data.summary.loaderSumIdlingHours,
    idlingDiff: data.summary.loaderSumIdlingHours_diff,
    noOfAssets: data.summary.number_of_loaders,
    dateRange: data.summary.date_range,
  };

  const fuelData = {
    total:
      Number(data.summary.loaderSumFuelConsumption) +
      Number(data.summary.loaderIdleSumFuelConsumption),
    totalDiff:
      (Number(data.summary.loaderSumFuelConsumption_diff) *
        Number(data.summary.loaderIdleSumFuelConsumption_diff)) /
      100,
    productive: data.summary.loaderSumFuelConsumption,
    productiveDiff: data.summary.loaderSumFuelConsumption_diff,
    idle: data.summary.loaderIdleSumFuelConsumption,
    idleDiff: data.summary.loaderIdleSumFuelConsumption_diff,
  };

  const loaderAssets = data.assets.filter((item) =>
    ['loader'].includes(item.asset_type)
  );

  return (
    <>
      <CustomTabPanel value={value} index={2}>
        <div>
          <ProductiveHours
            enableDarkTheme={enableDarkTheme}
            unitSystem={unitSystem}
            loadingStates={loadingStates}
            chartData={data.fleetPeriodSummary.loaders}
            detailsData={data.fleetDetails.loaders}
          />
          <DateRangeDiv
            enableDarkTheme={enableDarkTheme}
            isSelected={isSelected}
            setIsSelected={setIsSelected}
            handleDateChange={handleDateChange}
            startDate={startDate}
            endDate={endDate}
            yesterday={yesterday}
          />

          <GridAnalytics
            enableDarkTheme={enableDarkTheme}
            unitSystem={unitSystem}
            assetOverview={assetOverview}
            isLoading={loadingStates.isLoadingFleetSummary}
            summary={summaryData}
            score={data.score}
            data={data.summaryChart.loaders}
          />

          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            sx={{
              paddingTop: '15px',
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
          >
            <Grid item lg={9} sm={12} md={12}>
              <Grid container spacing={2} justifyContent="space-between">
                <HoursBreakdown
                  enableDarkTheme={enableDarkTheme}
                  title="Loader Hours Breakdown"
                  isLoading={loadingStates.isLoadingFleetSummary}
                  data={loaderBreakDown}
                />
                <FuelConsumption
                  enableDarkTheme={enableDarkTheme}
                  title="Loader Fuel Consumption"
                  isLoading={loadingStates.isLoadingFleetSummary}
                  data={fuelData}
                  unitSystem={unitSystem}
                />
              </Grid>
            </Grid>
            <Suggestions
              enableDarkTheme={enableDarkTheme}
              minHeight="300px"
              data={[]}
              isLoading={false}
              startDate={startDate as string}
              isSelected={isSelected}
            />
          </Grid>
          <AssetView
            enableDarkTheme={enableDarkTheme}
            unitSystem={unitSystem}
            isLoading={loadingStates.isLoadingFleetAssets}
            isSelected={isSelected}
            data={loaderAssets}
            isLoaderType={true}
          />
        </div>
      </CustomTabPanel>
    </>
  );
};

export default Loader;
