import { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ThemePalette } from 'mui.theme';
import ProductionSelector from 'views/Production/components/Selector/Selector';
import AssetItem from './Components/AssetItem';
import MultiColorCircularLoader from 'views/Production/components/BoxLoader/MultiColorCircularLoader';
import type { DeviceStats } from 'types/utilization.types';
import { calculateUtilization } from 'views/Utilization/utils';

interface Props {
  enableDarkTheme: boolean;
  title: string;
  isLoading: boolean;
  unitSystem: string;
  data: DeviceStats[];
  isLoaderType?: boolean;
}

const AssetWrapper = ({
  enableDarkTheme,
  title,
  isLoading,
  unitSystem,
  data,
  isLoaderType = false,
}: Props) => {
  let options = ['Production', 'Utilization', 'Fuel', 'Idling'];

  if (isLoaderType) {
    options = ['Utilization', 'Fuel', 'Idling'];
  }

  const sortOptions = ['Least Utilization', 'Most Utilization'];

  const [selectedSource, setSelectedSource] = useState<string>('Utilization');
  const [selectedSort, setSelectedSort] = useState<string>('Most Utilization');

  const sortedData = data
    .map((item) => {
      const noOfValidEntries = Object.values(item.date_stats).filter(
        (entry) => entry.utilization_engine_active_minute > 0
      ).length;

      const shiftHours = noOfValidEntries > 0 ? 10.5 * noOfValidEntries : 0;

      return {
        ...item,
        utilization: calculateUtilization(
          Number(shiftHours),
          Number(item.total_engine_working_minutes)
        ),
      };
    })
    .sort((a: any, b: any) => {
      if (selectedSort === 'Least Utilization') {
        return a.utilization - b.utilization;
      }
      return b.utilization - a.utilization;
    });

  return (
    <Grid item lg={6} md={12}>
      <Box
        sx={{
          padding: 2,
          backgroundColor: enableDarkTheme
            ? ThemePalette.dark.boxBackground
            : ThemePalette.light.boxBackground,
          borderRadius: '5px',
          border: '1px solid #2F445D',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: '13px',
                fontWeight: '600',
                paddingTop: '5px',
              }}
            >
              {title}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: '600',
                color: enableDarkTheme
                  ? ThemePalette.typography.lightGrey
                  : ThemePalette.typography.black,
                paddingRight: '15px',
                paddingTop: '5px',
              }}
            >
              Show
            </Typography>

            <ProductionSelector
              enableDarkTheme={enableDarkTheme}
              options={options}
              selected={selectedSource}
              setSelected={setSelectedSource}
              minWidth="150px"
            />

            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: '600',
                color: enableDarkTheme
                  ? ThemePalette.typography.lightGrey
                  : ThemePalette.typography.black,
                paddingLeft: '10px',
                paddingRight: '15px',
                paddingTop: '5px',
              }}
            >
              Sort By
            </Typography>

            <ProductionSelector
              enableDarkTheme={enableDarkTheme}
              options={sortOptions}
              selected={selectedSort}
              setSelected={setSelectedSort}
              minWidth="150px"
            />
          </Box>
        </Box>
        <Box
          sx={{
            paddingTop: '20px',
          }}
        >
          {isLoading ? (
            <MultiColorCircularLoader height="200px" />
          ) : (
            <>
              {sortedData.map((item: any, index: number) => {
                return (
                  <AssetItem
                    enableDarkTheme={enableDarkTheme}
                    data={item}
                    key={index}
                    selectedSource={selectedSource}
                    unitSystem={unitSystem}
                  />
                );
              })}
            </>
          )}
        </Box>
      </Box>
    </Grid>
  );
};

export default AssetWrapper;
