import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

export function calculateUtilization(
  totalEngineActiveMinutes: number,
  totalEngineWorkingMinutes: number
) {
  if (totalEngineActiveMinutes === 0 && totalEngineWorkingMinutes === 0) {
    return '0.00';
  }

  if (totalEngineActiveMinutes === 0) {
    return 'N/A';
  }

  const utilization =
    (totalEngineWorkingMinutes / totalEngineActiveMinutes) * 100;
  return utilization.toFixed(2);
}

export function calculatePercentageDifference(
  totalEngineActiveMinutes: number,
  totalEngineWorkingMinutes: number,
  totalEngineActiveMinutesDiff: number,
  totalEngineWorkingMinutesDiff: number
) {
  const currentUtilization =
    (totalEngineWorkingMinutes / totalEngineActiveMinutes) * 100;

  const previousActiveMinutes =
    totalEngineActiveMinutes / (1 + totalEngineActiveMinutesDiff / 100);
  const previousWorkingMinutes =
    totalEngineWorkingMinutes / (1 + totalEngineWorkingMinutesDiff / 100);

  const previousUtilization =
    (previousWorkingMinutes / previousActiveMinutes) * 100;

  const utilizationDiff =
    ((currentUtilization - previousUtilization) / previousUtilization) * 100;

  return utilizationDiff;
}

/**
 * Convert nanoseconds to minutes using Day.js
 * @param nanoseconds - The time in nanoseconds to be converted
 * @returns The time converted to minutes (formatted to 2 decimal places)
 */
export function convertNanosecondsToMinutes(nanoseconds: number): number {
  const milliseconds = nanoseconds / 1e6;

  const minutes = dayjs.duration(milliseconds, 'milliseconds').asMinutes();

  return parseFloat(minutes.toFixed(2));
}

export function isSumZeroOrEmpty(array: any[], field: string) {
  if (array.length === 0) return true;

  const sum = array.reduce(
    (acc: number, obj) => acc + (Number(obj[field]) || 0),
    0
  );

  return sum === 0;
}

export function getDataUnit(type: string, unitSystem: string) {
  if (type === 'Production') {
    return unitSystem === 'imperial' ? 'iTons' : 'Tons';
  }

  if (type === 'Fuel') {
    return unitSystem === 'imperial' ? 'gal' : 'l';
  }

  if (type === 'Utilization') {
    return '%';
  }

  if (type === 'Idling') {
    return 'mins';
  }
}

export const getColor = (value: number) => {
  if (value >= 0 && value <= 26) {
    return '#FF0068';
  } else if (value > 26 && value <= 50) {
    return '#FF7C54';
  } else {
    return '#1B9D59';
  }
};

export function daysLeftInYear() {
  const today: any = new Date();
  const endOfYear: any = new Date(today.getFullYear(), 11, 31);
  const timeDifference = endOfYear - today;
  const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  return daysLeft;
}

export function filterDataByPeriod(
  dataArray: Array<{
    period: string;
    date: string;
    total_engine_active_minutes: string;
    total_engine_working_minutes: string;
  }>,
  period: string
) {
  const today = new Date();

  let startDate: Date;
  switch (period) {
    case '7days':
      startDate = new Date(today.setDate(today.getDate() - 8));
      break;
    case '14days':
      startDate = new Date(today.setDate(today.getDate() - 15));
      break;
    case '1month':
      startDate = new Date(today.setDate(today.getDate() - 30));
      break;
    case '6months':
      startDate = new Date(today.setMonth(today.getMonth() - 6));
      break;
    default:
      startDate = new Date(today.setDate(today.getDate() - 8));
  }

  return dataArray
    .filter((item) => new Date(item.date) >= startDate)
    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
}
