import * as yup from 'yup';

export const createRuleGeneralInfo = yup
  .object({
    body: yup.object({
      ruleName: yup
        .string()
        .matches(
          /^[a-zA-Z0-9 _-]*$/,
          'Rule name can only contain letters, numbers, spaces, underscores, and dashes'
        )
        .required('Rule name is required'),
      assets: yup
        .array()
        .of(
          yup.object({
            id: yup.string().required(),
            display: yup.string().required(),
          })
        )
        .min(1),
    }),
  })
  .required();

export const createRuleConditionSchema = yup
  .object({
    body: yup.object({
      duration: yup.string().required(),
      durationInfractionCount: yup.number(),
      durationTimeframe: yup.number(),
      durationInfractionTimeframe: yup.string(),
      tagName: yup.string().required(),
      relationalOperator: yup.string().required(),
      value: yup.number().min(0).required(),
      unit: yup.string().required(),
    }),
  })
  .required();
