import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Grid, Box, Typography } from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Rectangle,
  Label,
  ReferenceLine,
} from 'recharts';
import { ThemePalette } from 'mui.theme';
import ProductionSelector from 'views/Production/components/Selector/Selector';
import { FlexBox } from 'components/Containers/FlexBox';
import MultiColorCircularLoader from 'views/Production/components/BoxLoader/MultiColorCircularLoader';
import { truncateTo2dp } from 'utils/helpers/general';
import { isSumZeroOrEmpty, filterDataByPeriod } from 'views/Utilization/utils';

interface Props {
  enableDarkTheme: boolean;
  unitSystem: string;
  isLoading: boolean;
  data: any[];
}

type ChartSource =
  | 'Last 7 Days'
  | 'Last 14 Days'
  | 'Last 4 Weeks'
  | 'Last 6 Months';

const periodMap: Record<ChartSource, string> = {
  'Last 7 Days': '7days',
  'Last 14 Days': '14days',
  'Last 4 Weeks': '1month',
  'Last 6 Months': '6months',
};

const options = [
  'Last 7 Days',
  'Last 14 Days',
  'Last 4 Weeks',
  'Last 6 Months',
];

const Chart = ({ enableDarkTheme, isLoading, data }: Props) => {
  const [selectedChartSource, setSelectedChartSource] =
    useState<string>('Last 7 Days');

  const [chartData, setChartData] = useState<any[]>([]);

  const formatTimestamp = (timestamp: number): string => {
    const date = dayjs(timestamp);
    const formattedDate = date.format('DD MMM');
    return formattedDate;
  };

  const getHighestValue = (arr: any) => {
    const highestValue: number = arr.reduce((max: any, obj: any) => {
      const currentValue = Number(obj.value);
      return !isNaN(currentValue) && currentValue > max ? currentValue : max;
    }, -Infinity);

    return highestValue;
  };

  useEffect(() => {
    const filteredData = filterDataByPeriod(
      data,
      periodMap[selectedChartSource as ChartSource]
    ).map((item) => ({
      ...item,
      value: truncateTo2dp(Number(item.total_engine_working_minutes) / 60),
    }));

    setChartData(filteredData);
  }, [data, selectedChartSource]);
  const average =
    chartData.reduce(
      (sum: number, entry: { value: string }) => sum + Number(entry.value),
      0
    ) / chartData.length;

  return (
    <Grid item lg={6} md={6} sm={12}>
      <Box
        sx={{
          padding: 2,
          backgroundColor: enableDarkTheme
            ? ThemePalette.dark.boxBackground
            : ThemePalette.light.toolBarBackground,
          borderRadius: '5px',
          border: '1px solid #2F445D',
          minHeight: '300px',
          maxHeight: '300px',
          position: 'relative',
        }}
      >
        <Typography
          sx={{
            fontWeight: '600',
            fontSize: '13px',
          }}
        >
          Productive Hours
        </Typography>

        <Box
          sx={{
            position: 'absolute',
            top: '16px',
            right: '16px',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <Typography
            sx={{
              fontSize: '12px',
              color: enableDarkTheme
                ? ThemePalette.typography.lightGrey
                : ThemePalette.typography.black,
            }}
          >
            Show
          </Typography>

          <ProductionSelector
            enableDarkTheme={enableDarkTheme}
            options={options}
            selected={selectedChartSource}
            setSelected={setSelectedChartSource}
            minWidth="150px"
          />
        </Box>

        {isLoading ? (
          <MultiColorCircularLoader height="300px" />
        ) : (
          <Box
            sx={{
              paddingTop: '15px',
            }}
          >
            {isSumZeroOrEmpty(chartData, 'value') ? (
              <Typography
                sx={{
                  fontSize: '11px',
                }}
              >
                No Chart data available
              </Typography>
            ) : (
              <FlexBox
                sx={{
                  maxWidth: 'inherit',
                  height: '230px',
                  paddingTop: '20px',
                  marginRight: '10px',
                  paddingBottom: '10px',
                }}
              >
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    width={700}
                    height={300}
                    data={chartData}
                    margin={{
                      top: 20,
                      right: 110,
                      left: 5,
                      bottom: 5,
                    }}
                    barSize={30}
                  >
                    <CartesianGrid
                      strokeDasharray="3 3"
                      stroke={ThemePalette.dark.cartesianGrid}
                      vertical={false}
                    />
                    <XAxis
                      dataKey="date"
                      tickFormatter={formatTimestamp}
                      tick={{ fontSize: 10 }}
                    />
                    <YAxis
                      tick={{ fontSize: 10 }}
                      label={{
                        value: `Productive Hours`,
                        angle: 270,
                        position: 'insideLeft',
                        fontSize: 10,
                        textAnchor: 'middle',
                        dy: 50,
                      }}
                      domain={[0, getHighestValue(data)]}
                    />
                    <Label
                      style={{
                        textAnchor: 'middle',
                        fontSize: '11px',
                      }}
                      angle={270}
                      value={'Productive Hours'}
                    />
                    <Tooltip
                      cursor={{ fill: '#1B2B4A' }}
                      wrapperStyle={{ outline: 'none', fontSize: '11px' }}
                      contentStyle={{
                        backgroundColor: 'transparent',
                      }}
                      itemStyle={{
                        color: enableDarkTheme
                          ? ThemePalette.typography.wheat
                          : ThemePalette.typography.black,
                      }}
                    />
                    <Bar
                      dataKey="value"
                      fill="#4A7BFA"
                      activeBar={<Rectangle fill="pink" stroke="blue" />}
                    />
                    <ReferenceLine
                      y={average}
                      label={{
                        value: `Avg ${average.toFixed(2)} Hours/Day`,
                        position: 'right',
                        fontSize: '8px',
                      }}
                      stroke="#4A7BFA"
                      strokeDasharray="3 3"
                      isFront
                    />
                  </BarChart>
                </ResponsiveContainer>
              </FlexBox>
            )}
          </Box>
        )}
      </Box>
    </Grid>
  );
};

export default Chart;
