import { useEffect, useState } from 'react';
import {
  Typography,
  List,
  ListItem,
  Box,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { type RootState } from 'store';
import { InteractiveModal } from 'components/Modals/InteractiveModal/BaseModal';
import { SWTextField } from 'components/Form/Input/SWTextField';
import { Selector } from 'components/Form/Selectors/Selector';
import { MultiSelector } from 'components/Form/Selectors/MultiSelector';
import { modalInputFieldStyle } from 'styles/global.css';
import RuleHandler from 'handlers/rule.handler';
import type { Notification } from 'types/notification.types';
import { validateYupSchema } from 'utils/validators/schema.validator';
import { createRuleGeneralInfo } from 'utils/schemas/rule.schema';
import { NOTIFICATION_LEVEL } from 'utils/enums';
import {
  handleSelectNotificationEmail,
  handleSelectAllNotificationEmail,
} from 'views/Settings/Rules/helpers/common';
import type { SelectOptions } from 'types/utils.types';
import { ThemePalette } from 'mui.theme';

interface Props {
  open: boolean;
  handleAbort: () => void;
  title: string;
  ruleData: Notification;
  confirmCancellation?: boolean;
  enableDarkTheme: boolean;
}

/**
 *
 * The UpdateRuleModal modal is a modal
 * that is used to update rule's information.
 */
export const UpdateRuleModal = ({
  open,
  handleAbort,
  title,
  ruleData,
  enableDarkTheme,
}: Props) => {
  const ruleHandler = new RuleHandler();

  const { externalUserList: externalUserListState } = useSelector(
    (state: any) => state.externalUserReducer
  );

  const { internalUserList: internalUserListState } = useSelector(
    (state: RootState) => state.internalUserReducer
  );

  const userRole = useSelector((state: RootState) => state.authReducer.role);

  let userEmails: SelectOptions[];

  if (userRole === 'external-user' || userRole === 'external-admin') {
    userEmails = externalUserListState.map((eUser: any) => ({
      id: eUser.userId,
      display: eUser.email,
    }));
  } else {
    userEmails = [
      ...externalUserListState.map((eUser: any) => ({
        id: eUser.userId,
        display: eUser.email,
      })),
      ...internalUserListState.map((iUser) => ({
        id: iUser.userId,
        display: iUser.email,
      })),
    ];
  }

  const [rule, setRule] = useState<{
    ruleName: string;
    notificationLevel: any;
    alertByEmail: boolean;
    alertByUI: boolean;
    alertByNotificationCenter: boolean;
    distributionUsers: any;
  }>({
    ruleName: ruleData.ruleName,
    notificationLevel: ruleData.notificationLevel,
    alertByEmail: ruleData.alertByEmail,
    alertByNotificationCenter: ruleData.alertByNotificationCenter,
    alertByUI: ruleData.alertByUI,
    distributionUsers: userEmails.filter((item) =>
      ruleData.distributionUsers.includes(item.id)
    ),
  });
  const [isValidForm, setFormValidity] = useState(false);

  /**
   * This UseEffect Validates the form as user fills out fields
   */
  useEffect(() => {
    const validateForm = async () =>
      await validateYupSchema(rule, createRuleGeneralInfo);

    void validateForm().then((isValid) => {
      setFormValidity(isValid);
    });
  }, [rule]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setRule({
      ...rule,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    await ruleHandler.updateRule(ruleData.ruleId, rule);
    handleAbort();
  };

  return (
    <InteractiveModal
      initialState={open}
      title={title}
      handleAbort={handleAbort}
      SuccessButtonProps={{
        onClick: handleSubmit,
        disabled: !isValidForm,
        label: 'SAVE EDITS',
      }}
      ModalContentProps={{
        gridItemProps: {
          lg: 12,
        },
      }}
      confirmCancellation
      enableDarkTheme={enableDarkTheme}
    >
      {/* Rule Name TextField */}
      <SWTextField
        key="ruleName"
        name="ruleName"
        label="Rule Name"
        placeholder="Enter Rule Name"
        TextFieldProps={{
          name: 'ruleName',
          value: rule.ruleName,
          onChange: handleChange,
          InputProps: {
            sx: {
              ...modalInputFieldStyle,
              minWidth: '400px',
            },
          },
        }}
        enableDarkTheme={enableDarkTheme}
      />
      <Typography
        key="small-info-text"
        variant="small"
        sx={{
          paddingTop: '0px',
          color: enableDarkTheme
            ? ThemePalette.typography.white
            : ThemePalette.typography.black,
        }}
      >
        Only underscores, spaces and dashes are permitted as special characters
      </Typography>

      {/* Rule Severity Selector */}
      <Selector
        value={rule.notificationLevel}
        onChange={(event) => {
          setRule({
            ...rule,
            notificationLevel: event.target.value.toString(),
          });
        }}
        selectorOptions={NOTIFICATION_LEVEL}
        selectSx={{
          minWidth: '100px',
          maxWidth: '200px',
          marginRight: '0px',
        }}
        enableDarkTheme={enableDarkTheme}
      />

      {/** Checkbox group to get Notification Distribution method */}
      <List>
        <ListItem>
          <Box key="emailNotification">
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    color: enableDarkTheme
                      ? ThemePalette.typography.cornflowerBlue
                      : ThemePalette.typography.primary,
                    '&.Mui-checked': {
                      color: enableDarkTheme
                        ? ThemePalette.typography.cornflowerBlue
                        : ThemePalette.typography.primary,
                    },
                    '&.Mui-disabled': {
                      color: ThemePalette.typography.secondary,
                    },
                  }}
                />
              }
              checked={rule.alertByEmail}
              label="By Email"
              onChange={() => {
                setRule({
                  ...rule,
                  alertByEmail: !rule.alertByEmail,
                  distributionUsers: !rule.alertByEmail
                    ? rule.distributionUsers
                    : [],
                });
              }}
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
            />
          </Box>

          <MultiSelector
            value={rule.distributionUsers}
            renderValue={(selected) => {
              return `${selected?.length || 0} Selected`;
            }}
            handleSelectOne={(event: any) => {
              handleSelectNotificationEmail(event, rule, setRule);
            }}
            handleSelectAll={() => {
              handleSelectAllNotificationEmail(userEmails, rule, setRule);
            }}
            selectorOptions={userEmails}
            minWidth="400px"
            enableDarkTheme={enableDarkTheme}
          />
        </ListItem>
      </List>
      <List>
        <ListItem>
          <Box key="applicationUi">
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    color: enableDarkTheme
                      ? ThemePalette.typography.cornflowerBlue
                      : ThemePalette.typography.primary,
                    '&.Mui-checked': {
                      color: enableDarkTheme
                        ? ThemePalette.typography.cornflowerBlue
                        : ThemePalette.typography.primary,
                    },
                    '&.Mui-disabled': {
                      color: ThemePalette.typography.secondary,
                    },
                  }}
                />
              }
              checked={rule.alertByUI}
              label="In Application Interface/UI"
              onChange={() => {
                setRule({
                  ...rule,
                  alertByUI: !rule.alertByUI,
                });
              }}
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
            />
          </Box>
        </ListItem>
        <ListItem>
          <Box key="NotificationCenter">
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    color: enableDarkTheme
                      ? ThemePalette.typography.cornflowerBlue
                      : ThemePalette.typography.primary,
                    '&.Mui-checked': {
                      color: enableDarkTheme
                        ? ThemePalette.typography.cornflowerBlue
                        : ThemePalette.typography.primary,
                    },
                    '&.Mui-disabled': {
                      color: ThemePalette.typography.secondary,
                    },
                  }}
                />
              }
              checked={rule.alertByNotificationCenter}
              label="In Notification Center"
              onChange={() => {
                setRule({
                  ...rule,
                  alertByNotificationCenter: !rule.alertByNotificationCenter,
                });
              }}
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
            />
          </Box>
        </ListItem>
      </List>
    </InteractiveModal>
  );
};
