import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useAppSelector } from 'store/hook';
import { isDarkTheme } from 'utils/theme';
import useDateRange from 'hooks/dateRange';
import HeaderMenu from './HeaderMenu';
import Loader from './Loader';
import AllFleet from './AllFleet';
import Hauler from './Hauler';
import ProductionHandler from 'handlers/production.handler';

const Production = () => {
  const productionHandler = new ProductionHandler();
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const { data, loadingStates } = useAppSelector(
    (state) => state.productionReducer
  );
  const unitSystem = useAppSelector((state) => state.persistedReducer).customer
    .unitSystem;
  const { assetOverView: assetOverview } = useAppSelector(
    (state) => state.assetReducer
  );
  const enableDarkTheme = isDarkTheme(theme);
  const [value, setValue] = useState(0);
  const [isSelected, setIsSelected] = useState('1D');
  const [initRun, setInitRun] = useState<boolean>(true);
  const [isCustomDateChanged, setIsCustomDateChanged] = useState(false);

  const { startDate, endDate, setStartDate, setEndDate, yesterday } =
    useDateRange();

  const handleDateChange = (range: any) => {
    const [startDate, endDate] = range;
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleChange = (newValue: number) => {
    setValue(newValue);
  };

  const fetchData = async (range: string, startDate: Date, endDate: Date) => {
    const formattedStartDate = dayjs(startDate).format('YYYY-MM-DD');
    const formattedEndDate = dayjs(endDate).format('YYYY-MM-DD');
    const shouldSendDate = isSelected === 'Custom';
    await productionHandler.get(
      range,
      formattedStartDate,
      formattedEndDate,
      shouldSendDate,
      initRun,
      value === 2 ? 'loader' : value === 1 ? 'loader' : 'all'
    );
    setInitRun(false);
  };

  useEffect(() => {
    if (isSelected === 'Custom') {
      if (!isCustomDateChanged) {
        setIsCustomDateChanged(true);
      }
      if (isCustomDateChanged && startDate && endDate) {
        void fetchData(isSelected, startDate, endDate);
      }
    } else if (startDate && endDate) {
      setIsCustomDateChanged(false);
      void fetchData(isSelected, startDate, endDate);
    }
  }, [isSelected, startDate, endDate]);

  return (
    <>
      <HeaderMenu value={value} handleChange={handleChange} />
      <Loader value={value} />
      <Hauler
        value={value}
        enableDarkTheme={enableDarkTheme}
        isSelected={isSelected}
        setIsSelected={setIsSelected}
        handleDateChange={handleDateChange}
        startDate={startDate}
        endDate={endDate}
        yesterday={yesterday}
        unitSystem={unitSystem}
        loadingStates={loadingStates}
        assetOverview={assetOverview}
        data={data.allFleet}
      />
      <AllFleet
        value={value}
        enableDarkTheme={enableDarkTheme}
        isSelected={isSelected}
        setIsSelected={setIsSelected}
        handleDateChange={handleDateChange}
        startDate={startDate}
        endDate={endDate}
        yesterday={yesterday}
        data={data.allFleet}
        loadingStates={loadingStates}
        unitSystem={unitSystem}
        assetOverview={assetOverview}
      />
    </>
  );
};

export default Production;
