import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  Card,
  CardContent,
  Grid,
  Divider,
  Button,
  ButtonGroup,
  IconButton,
  CircularProgress,
  type SelectChangeEvent,
  Collapse,
  Alert,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBack from '@mui/icons-material/ArrowBack';
import GridViewIcon from '@mui/icons-material/GridView';
import ListIcon from '@mui/icons-material/List';
import { Selector } from 'components/Form/Selectors/Selector';
import { PieChart, Pie, Cell } from 'recharts';
import { ThemePalette } from 'mui.theme';
import { modalInputFieldStyle } from 'styles/global.css';
import SurvivalAnalysisDetailsListView from 'views/SurvivalAnalysis/Helper/Modal/SurvivalAnalysis.details.listView';
import {
  type CumulativeOverview,
  type AssetsOverview,
  type ServiceView,
  type EolDates,
} from 'types/survivalAnalysis.types';
import { SurvivalAnalysisDetailsCard } from 'views/SurvivalAnalysis/Helper/Modal/SurvivalAnalysis.details.card';
import {
  sortOrders,
  type MetricDetails,
  formatToDollars,
  calculateFinancialMetrics,
  getNumericValue,
} from 'views/SurvivalAnalysis/Helper/Function/helper.function';
import { type DashboardSquares } from 'types/squares.type';
import {
  getUniqueAssetsFromComponent,
  getUniqueValues,
} from 'views/SurvivalAnalysis/Helper/Function/component.helper.function';
import { GetIcons } from '../../Helper/Icons/GetIcons.function';
import componentsImage from '../../Helper/Icons/componenent.png';
import assetImage from '../../Helper/Icons/vehicle.png';
import LoadingDots from 'views/FleetOverview/components/AssetCardsV2/LoadingDots';
import CloseIcon from '@mui/icons-material/Close';

interface Option {
  id: string;
  display: string;
}

export const OverviewModalAssetDetails = ({
  enableDarkTheme,
  onShowDetails,
  displayItems,
  assets,
  assetsPerSubComponent,
  uniqueAssets,
  fetchSquaresData,
  detailType,
  eolDates,
  handleModalSuccess,
}: {
  enableDarkTheme: boolean;
  onShowDetails: any;
  displayItems: ServiceView;
  assets: AssetsOverview[];
  assetsPerSubComponent: CumulativeOverview[];
  uniqueAssets: ServiceView[];
  fetchSquaresData: (
    selectedStartDate: Date,
    selectedEndDate: Date
  ) => Promise<DashboardSquares[]>;
  detailType: string;
  eolDates?: EolDates[];
  handleModalSuccess: () => void;
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [sortCriteria, setSortCriteria] = useState<string>('eol-low');
  const [filter, setFilter] = useState('');
  const [displayMode, setDisplayMode] = useState('grid');
  const [selectedAsset, setSelectedAsset] = useState('');
  const [selectedComponent, setSelectedComponent] = useState('');
  const [selectedSubComponent, setSelectedSubComponent] = useState('');
  const [costPeriod, setCostPeriod] = useState('1');
  const [componentsOptions, setComponentsOptions] = useState<Option[]>([]);
  const [assetOptions, setAssetOptions] = useState<Option[]>([]);
  const [subComponentOptions, setSubComponentOptions] = useState<Option[]>([]);
  const [filteredAssets, setFilteredAssets] = useState<any[]>([]);
  const [financialMetricsValues, setFinancialMetricsValues] =
    useState<MetricDetails>();
  const [financialMetricsValuesLoading, setFinancialMetricsValuesLoading] =
    useState(false);
  const [upComingService, setUpComingService] = useState('');
  const [open, setOpen] = React.useState(true);

  const [pieData, setPieData] = useState<any>([
    {
      name: 'Spend',
      value: 0,
    },
    {
      name: 'Remaining',
      value: 100,
    },
  ]);

  const pieColors = ['#0088FE', '#B2BEB5'];

  useEffect(() => {
    if (detailType === 'Assets') {
      setSelectedAsset(displayItems.item_id);
      setAssetOptions(
        uniqueAssets.map((asset) => ({
          id: asset.item_id,
          display: asset.item_id, // Adjust display format as necessary
        }))
      );
    } else {
      setSelectedComponent(displayItems.components_category);
      setSelectedSubComponent(displayItems.sub_component);

      const updatedAssetsItems = getUniqueAssetsFromComponent(
        uniqueAssets,
        displayItems.components_category,
        displayItems.sub_component
      );

      const uniqueAssetIds = new Set();
      const uniqueAssetsArray: React.SetStateAction<Option[]> = [];

      updatedAssetsItems.forEach((asset) => {
        if (!uniqueAssetIds.has(asset.asset_id)) {
          uniqueAssetIds.add(asset.asset_id);
          uniqueAssetsArray.push({
            id: asset.asset_id,
            display: asset.asset_id, // Adjust display format as necessary
          });
        }
        setAssetOptions([
          { id: 'All', display: 'All Assets' },
          ...uniqueAssetsArray,
        ]);
        setSelectedAsset('All');
      });
    }
  }, [detailType, displayItems, uniqueAssets]);

  useEffect(() => {
    const fetchData = async () => {
      setFinancialMetricsValuesLoading(true);
      const today = new Date();
      const oneYearAgo = new Date();
      oneYearAgo.setFullYear(today.getFullYear() - parseInt(costPeriod));
      const isAllselectedAsset = selectedAsset === 'All' ? '' : selectedAsset;
      try {
        const financialMetricData = await fetchSquaresData(oneYearAgo, today);

        const financialMetric = calculateFinancialMetrics(
          financialMetricData,
          isAllselectedAsset
        );
        setFinancialMetricsValues(financialMetric);

        const numericPercentageOfTotal = getNumericValue(
          financialMetric?.percentageOfTotal
        );

        setPieData([
          {
            name: 'Spend',
            value: numericPercentageOfTotal,
          },
          {
            name: 'Remaining',
            value: 100 - numericPercentageOfTotal,
          },
        ]);
        setFinancialMetricsValuesLoading(false);
      } catch (error) {
        console.error('Error fetching or processing data:', error);
        setFinancialMetricsValuesLoading(false);
      }
    };

    if (selectedAsset && costPeriod) {
      void fetchData();
    }
  }, [selectedAsset, costPeriod]);

  useEffect(() => {
    const filterAndSortAssets = () => {
      const isAllselectedComponents =
        selectedComponent === 'All' ? '' : selectedComponent;
      const isAllselectedSubComponents =
        selectedSubComponent === 'All' ? '' : selectedSubComponent;
      const isAllselectedAsset = selectedAsset === 'All' ? '' : selectedAsset;

      if (detailType === 'Assets') {
        let updatedAssets = assets.filter((asset) =>
          asset.asset_id.includes(isAllselectedAsset)
        );
        updatedAssets = updatedAssets.filter((asset) =>
          asset.components_category
            ?.toLowerCase()
            .includes((isAllselectedComponents || searchTerm)?.toLowerCase())
        );

        updatedAssets = updatedAssets.filter((asset) =>
          asset.sub_component?.includes(isAllselectedSubComponents)
        );
        updatedAssets = updatedAssets.filter((asset) => {
          if (filter === 'high') {
            // Include both 'eol' and 'high' when the filter is set to 'high'
            return (
              asset.work_order_age_category?.includes('eol') ||
              asset.work_order_age_category?.includes('high')
            );
          } else {
            // Include only the specified filter for other values
            return asset.work_order_age_category?.includes(filter);
          }
        });
        updatedAssets = sortOrders(updatedAssets, sortCriteria);
        console.log('setFiltered Assets details', updatedAssets);
        setFilteredAssets(updatedAssets);
      } else {
        const updatedAssetsItems = getUniqueAssetsFromComponent(
          uniqueAssets,
          isAllselectedComponents,
          isAllselectedSubComponents
        );
        let filteredComponents = Array.from(updatedAssetsItems).filter(
          (component) =>
            component.sub_component?.includes(isAllselectedSubComponents)
        );

        filteredComponents = Array.from(filteredComponents).filter(
          (component) => component.asset_id?.toLowerCase().includes(searchTerm)
        );
        filteredComponents = Array.from(filteredComponents).filter(
          (component) => {
            if (filter === 'high') {
              // Include both 'eol' and 'high' when the filter is set to 'high'
              return (
                component.work_order_age_category?.includes('eol') ||
                component.work_order_age_category?.includes('high')
              );
            } else {
              // Include only the specified filter for other values
              return component.work_order_age_category?.includes(filter);
            }
          }
        );

        filteredComponents = Array.from(filteredComponents).filter(
          (component) => component.asset_id?.includes(isAllselectedAsset)
        );
        filteredComponents = sortOrders(filteredComponents, sortCriteria);
        console.log(
          'setFiltered Assets details',
          Array.from(filteredComponents)
        );

        setFilteredAssets(Array.from(filteredComponents));
      }
    };

    filterAndSortAssets();
  }, [
    assets,
    selectedAsset,
    selectedComponent,
    selectedSubComponent,
    sortCriteria,
    searchTerm,
    filter,
    detailType,
    uniqueAssets,
  ]);

  useEffect(() => {
    const initializeOptions = () => {
      setComponentsOptions([
        { id: 'All', display: 'All Components' }, // Blank option
        ...Array.from(displayItems.componentsCategories).map((category) => ({
          id: category,
          display: category,
        })),
      ]);

      setSubComponentOptions([
        { id: 'All', display: 'All Sub-Components' }, // Blank option
        ...Array.from(displayItems.subComponentCategories).map((category) => ({
          id: category,
          display: category,
        })),
      ]);
      if (detailType === 'Assets') {
        setSelectedComponent('All');
        setSelectedSubComponent('All');
      } else {
        setSelectedSubComponent('All');
      }
    };

    initializeOptions();
  }, [detailType, displayItems, assetsPerSubComponent]);

  const handleChange = (event: {
    target: { value: { toString: () => React.SetStateAction<string> } };
  }) => {
    setSortCriteria(event.target.value.toString());
  };

  const handleFilterChange = (filter: React.SetStateAction<string>) => {
    setFilter(filter);
  };

  const handleDisplayModeChange = (mode: React.SetStateAction<string>) => {
    setDisplayMode(mode);
  };

  const handleAssetChange = (event: SelectChangeEvent<number>) => {
    setSelectedAsset(event.target.value.toString());
  };

  const handleComponentChange = (event: SelectChangeEvent<number>) => {
    setSelectedComponent(event.target.value.toString());
  };

  const handleSubComponentChange = (event: SelectChangeEvent<number>) => {
    setSelectedSubComponent(event.target.value.toString());
  };

  const handleCostPeriodChange = (event: SelectChangeEvent<number>) => {
    setCostPeriod(event.target.value as string);
  };

  useEffect(() => {
    if (eolDates && selectedAsset) {
      const filtered = eolDates.filter(
        (item) => item.asset_id === selectedAsset
      );

      if (filtered.length === 0) {
        console.log('No matching asset_id found');
        return;
      }

      const earliest = filtered.reduce((earliestItem, currentItem) => {
        return new Date(currentItem.eol) < new Date(earliestItem.eol)
          ? currentItem
          : earliestItem;
      });

      const earliestDate = new Date(earliest.eol);
      setUpComingService(earliestDate.toLocaleDateString());
    }
  }, [eolDates]);

  return (
    <>
      <Box
        sx={{
          width: '100%',
          mb: 4,
          display: 'flex',
          alignItems: 'left',
          justifyContent: 'left',
        }}
      >
        <IconButton
          sx={{ padding: 0, marginRight: 1 }}
          onClick={() => onShowDetails(null)}
        >
          <ArrowBack
            style={{
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.grey,
            }}
          />
        </IconButton>
        <Typography
          fontStyle={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
          variant="h6"
        >
          {detailType} Details
        </Typography>
      </Box>
      <Box sx={{ width: '100%', mb: 6 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Typography
              fontStyle={{
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
            >
              Asset IDs
            </Typography>
            <Selector
              enableDarkTheme={enableDarkTheme}
              value={selectedAsset}
              selectorOptions={assetOptions}
              onChange={handleAssetChange}
              formControlSx={{
                minWidth: '100%',
              }}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <Typography
              fontStyle={{
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
            >
              Component
            </Typography>
            <Selector
              enableDarkTheme={enableDarkTheme}
              value={selectedComponent}
              selectorOptions={componentsOptions}
              onChange={handleComponentChange}
              formControlSx={{
                minWidth: '100%',
              }}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <Typography
              fontStyle={{
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
            >
              sub-Component
            </Typography>
            <Selector
              enableDarkTheme={enableDarkTheme}
              value={selectedSubComponent}
              selectorOptions={subComponentOptions}
              onChange={handleSubComponentChange}
              formControlSx={{
                minWidth: '100%',
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={2}>
        <Box
          sx={{ display: 'flex', alignItems: 'center', width: '100%', mb: 2 }}
        >
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} md={6}>
              <Card
                className="card"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  backgroundColor: enableDarkTheme
                    ? ThemePalette.dark.modalBackground
                    : ThemePalette.light.menuOptions,
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                }}
              >
                <CardContent>
                  <Box sx={{ textAlign: 'center' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'left',
                        mb: 4,
                      }}
                    >
                      <Typography variant="h6">Selection Details</Typography>
                      {upComingService && (
                        <Typography sx={{ ml: 1 }} variant="body2">
                          Upcoming Service: <b>{upComingService}</b>
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  <Box sx={{ textAlign: 'left' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'left',
                      }}
                    >
                      <GetIcons
                        icon={
                          detailType === 'Assets' ? assetImage : componentsImage
                        }
                        enableDarkTheme={enableDarkTheme}
                      />
                      <Box sx={{ ml: 4, mr: 4 }}>
                        <Typography variant="body1">
                          <strong>Asset ID:</strong>
                        </Typography>
                        <Typography variant="body1">
                          {selectedAsset || 'Not Selected'}
                        </Typography>
                      </Box>
                      <Divider orientation="vertical" flexItem />
                      <Box sx={{ ml: 4, mr: 4 }}>
                        <Typography variant="body1">
                          <strong>Component:</strong>
                        </Typography>
                        <Typography variant="body1">
                          {selectedComponent || 'Not Selected'}
                        </Typography>
                      </Box>
                      <Divider orientation="vertical" flexItem />
                      <Box sx={{ ml: 4, mr: 4 }}>
                        <Typography variant="body1">
                          <strong>sub-Component:</strong>
                        </Typography>
                        <Typography variant="body1">
                          {selectedSubComponent || 'Not Selected'}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card
                className="card"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  backgroundColor: enableDarkTheme
                    ? ThemePalette.dark.modalBackground
                    : ThemePalette.light.menuOptions,
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                }}
              >
                <CardContent>
                  {!financialMetricsValuesLoading && selectedAsset !== 'All' ? (
                    <Box
                      sx={{
                        mb: 2,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Box sx={{ mr: 2 }}>
                        <Typography>Cost</Typography>
                        <Selector
                          enableDarkTheme={enableDarkTheme}
                          value={costPeriod}
                          selectorOptions={[
                            { id: '1', display: '1 Year' },
                            { id: '2', display: '2 Year' },
                            { id: '3', display: '3 Year' },
                            { id: '4', display: '4 Year' },
                          ]}
                          onChange={handleCostPeriodChange}
                          formControlSx={{
                            minWidth: '100%',
                          }}
                        />
                      </Box>
                      <Box sx={{ mr: 2 }}>
                        <Typography variant="body2">
                          Spend on this Asset
                        </Typography>
                        <Typography variant="h6">
                          {formatToDollars(financialMetricsValues?.total_cost)}
                        </Typography>
                        <Typography variant="body2" color="error">
                          {financialMetricsValues?.percentageFromAverage} more
                          than average
                        </Typography>
                      </Box>
                      <Divider orientation="vertical" flexItem />
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <PieChart width={100} height={100}>
                          <Pie
                            data={pieData}
                            cx="50%"
                            cy="50%"
                            outerRadius={50}
                            innerRadius={30}
                            dataKey="value"
                          >
                            {pieData.map((entry: any, index: number) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={pieColors[index]}
                                stroke="none"
                              />
                            ))}
                          </Pie>
                        </PieChart>
                        <Box sx={{ ml: 2 }}>
                          <Typography variant="body2">
                            Asset Vs Fleet
                          </Typography>
                          <Typography variant="h6" sx={{ ml: 1 }}>
                            {financialMetricsValues?.percentageOfTotal}
                          </Typography>
                          <Typography variant="body2" sx={{ ml: 1 }}>
                            of total spend of{' '}
                            {formatToDollars(financialMetricsValues?.total_sum)}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%', // Ensures the Box takes the full height of the CardContent
                        textAlign: 'center', // Optional: Center text inside the Typography
                      }}
                    >
                      {financialMetricsValuesLoading &&
                      selectedAsset !== 'All' ? (
                        <Typography variant="body2">
                          Loading Asset Data
                          <LoadingDots enableDarkTheme={enableDarkTheme} />
                        </Typography>
                      ) : (
                        <Typography variant="body2">
                          Asset is not selected
                        </Typography>
                      )}
                    </Box>
                  )}{' '}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', mb: 2 }}>
        <Typography
          sx={{ paddingRight: '5px' }}
          fontStyle={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          All {detailType === 'Assets' ? 'Components' : 'Assets'}
        </Typography>

        <TextField
          variant="outlined"
          size="small"
          placeholder="Search for a component"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
          sx={{ width: '400px' }} // You can set this to any width you prefer
          InputProps={{
            sx: {
              height: modalInputFieldStyle.height,
              minWidth: '131px',
              backgroundColor: enableDarkTheme
                ? ThemePalette.dark.menuOptions
                : ThemePalette.light.menuOptions,
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            },
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon
                  style={{
                    color: enableDarkTheme
                      ? ThemePalette.typography.white
                      : ThemePalette.typography.grey,
                  }}
                />
              </InputAdornment>
            ),
          }}
        />
        <ButtonGroup sx={{ ml: 2 }}>
          <Button
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.wheat
                : ThemePalette.typography.black,
              borderColor: ThemePalette.typography.grey,
              background:
                filter === '' ? ThemePalette.typography.grey : '00FFFFFF',
            }}
            onClick={() => {
              handleFilterChange('');
            }}
          >
            All
          </Button>

          <Button
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.wheat
                : ThemePalette.typography.black,
              borderColor: ThemePalette.typography.grey,
              background:
                filter === 'high' ? ThemePalette.typography.grey : '00FFFFFF',
            }}
            onClick={() => {
              handleFilterChange('high');
            }}
          >
            High Risk
          </Button>
          <Button
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.wheat
                : ThemePalette.typography.black,
              borderColor: ThemePalette.typography.grey,
              background:
                filter === 'med' ? ThemePalette.typography.grey : '00FFFFFF',
            }}
            onClick={() => {
              handleFilterChange('med');
            }}
          >
            Medium Risk
          </Button>
          <Button
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.wheat
                : ThemePalette.typography.black,
              borderColor: ThemePalette.typography.grey,
              background:
                filter === 'low' ? ThemePalette.typography.grey : '00FFFFFF',
            }}
            onClick={() => {
              handleFilterChange('low');
            }}
          >
            Low Risk
          </Button>
        </ButtonGroup>

        <Box sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
          {' '}
          <ButtonGroup variant="outlined" sx={{ mr: 2 }}>
            <IconButton
              onClick={() => {
                handleDisplayModeChange('grid');
              }}
            >
              <GridViewIcon
                style={{
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.grey,
                }}
              />
            </IconButton>
            <IconButton
              onClick={() => {
                handleDisplayModeChange('list');
              }}
            >
              <ListIcon
                style={{
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.grey,
                }}
              />
            </IconButton>
          </ButtonGroup>
          <Typography
            sx={{ marginRight: '10px' }}
            fontSize="14px"
            fontStyle={{
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
          >
            Sort By
          </Typography>
          <Selector
            enableDarkTheme={enableDarkTheme}
            value={sortCriteria}
            selectorOptions={[
              { id: 'low-eol', display: 'Low to High Risk' },
              { id: 'eol-low', display: 'High to Low Risk' },
            ]}
            onChange={handleChange}
          />
        </Box>
      </Box>
      {displayMode === 'grid' ? (
        <>
          <Collapse in={open}>
            <Alert
              severity="info"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ fontSize: '1rem', marginBottom: '1rem' }} // Set the text to a small size and add some space below
            >
              Charts shown below are historical distribution of components MTTR
              across assets
            </Alert>
          </Collapse>
          <SurvivalAnalysisDetailsCard
            components={filteredAssets}
            enableDarkTheme={enableDarkTheme}
            displayMode={displayMode}
            assetsPerSubComponent={assetsPerSubComponent}
            detailType={detailType}
            eolDates={eolDates}
            handleModalSuccess={handleModalSuccess}
          ></SurvivalAnalysisDetailsCard>
        </>
      ) : (
        <Card
          className="card"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            backgroundColor: enableDarkTheme
              ? ThemePalette.dark.modalBackground
              : ThemePalette.light.menuOptions,
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          <SurvivalAnalysisDetailsListView
            components={filteredAssets}
            enableDarkTheme={enableDarkTheme}
            displayMode={displayMode}
            assetsPerSubComponent={assetsPerSubComponent}
            detailType={detailType}
          />
        </Card>
      )}
    </>
  );
};

export default OverviewModalAssetDetails;
