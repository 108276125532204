import type {
  ICreateRulePayload,
  ICreateRuleNotificationPayload,
} from 'types/payloads/rule.payload.types';
import type { RuleCondition, DurationUnit } from 'types/notification.types';
import { capitalizeFirstLetter } from 'utils/helpers/string.manipulation';
import {
  defaultTabStyle,
  disabledTabStyle,
} from '../components/NotificationList.styled';
import {
  type SelectOptionsWithTagName,
  type SelectOptions,
  type TGroupedMultiSelectOptions,
} from 'types/utils.types';
import type { Asset } from 'types/asset.types';
import { resolveAssetTypeDisplay } from 'utils/helpers/assets';
import { UNITS } from 'utils/enums';
import { convertBasedOnUnitSystem } from 'utils/helpers/unitSystem';

/**
 * @param event
 * @returns void
 *
 * This function is used to handle the change event
 * of the asset operator selector.
 * It will add or remove the selected asset operator
 * from the asset's operator array.
 */
export const handleSelectAssetSingle = (
  event: { target: { value: any } },
  rule: ICreateRulePayload,
  setRule: React.Dispatch<React.SetStateAction<ICreateRulePayload>>
) => {
  const selectedValue = event.target.value;

  if (!selectedValue?.length) {
    setRule({
      ...rule,
      assets: [],
    });

    return;
  }

  const selectedAsset = JSON.parse(selectedValue[selectedValue.length - 1]);
  let ruleAssets = [...rule.assets];
  // get index of selected asset
  const idx = ruleAssets.findIndex(({ id }, idx) => {
    return id === selectedAsset.id;
  });

  // found the index of the asset, remove
  if (idx > -1) {
    ruleAssets.splice(idx, 1);
  } else {
    // Couldnt find index of asset add to list
    if (ruleAssets.length) {
      // asset list initialize already, push new to the end
      ruleAssets.push(selectedAsset);
    } else {
      // asset list not initialized, initialize new asset with selected asset
      ruleAssets = [selectedAsset];
    }
  }

  setRule({
    ...rule,
    assets: ruleAssets,
  });
};

/**
 *
 * @param operators
 * @param asset
 * @param setAsset
 * @returns void
 *
 * This function is used to handle the change event
 * of the asset's operator selector.
 *
 * It will add or remove all asset operators
 * from the asset's operators array.
 *
 */
export const handleSelectAssetAll = (
  assetsAll: Array<{
    id: string;
    display: string;
  }>,
  rule: ICreateRulePayload,
  setRule: React.Dispatch<React.SetStateAction<ICreateRulePayload>>
) => {
  const currRuleAssets = rule.assets;

  // if all assets are selected, remove all
  if (assetsAll.length === currRuleAssets.length) {
    setRule({
      ...rule,
      assets: [],
    });

    return;
  }

  // if not all assets are selected, add all
  setRule({
    ...rule,
    assets: assetsAll,
  });
};

/**
 *
 * @param value
 * @param condition
 * @param setCondition
 * @returns void
 *
 * This function is used to handle the change event
 * of the rules trigger duration unit.
 *
 */
export const handleSetDurationUnit = (
  value: DurationUnit,
  condition: RuleCondition,
  setCondition: React.Dispatch<React.SetStateAction<RuleCondition>>
) => {
  setCondition({
    ...condition,
    durationTimeframeUnit: value,
  });
};

/**
 * @param event
 * @returns void
 *
 * This function is used to handle the change event
 * of the notification user emails
 */
export const handleSelectNotificationEmail = (
  event: { target: { value: any } },
  notification: ICreateRuleNotificationPayload,
  setNotification: any
) => {
  // Get selected value from event arg
  const selectedValue = event.target.value;

  // Return empty arry when selected value is empty
  if (!selectedValue?.length) {
    setNotification({
      ...notification,
      distributionUsers: [],
      alertByEmail: false,
    });
    return;
  }

  const selectedEmail = JSON.parse(selectedValue[selectedValue.length - 1]);
  let distributionUsers = [...notification.distributionUsers];

  // Get index of selected email
  const idx = distributionUsers.findIndex(({ id }, idx) => {
    return id === selectedEmail.id;
  });

  // found the index of the selected email, we remove from the list of users
  if (idx > -1) {
    distributionUsers.splice(idx, 1);
  } else {
    distributionUsers = distributionUsers.length
      ? [...distributionUsers, selectedEmail]
      : [selectedEmail];
  }

  setNotification({
    ...notification,
    distributionUsers,
    alertByEmail: distributionUsers.length > 0,
  });
};

/**
 *
 * @param operators
 * @param asset
 * @param setAsset
 * @returns void
 *
 * This function is used to handle the change event
 * of the notification email.
 *
 * It will add or remove all emails from the Notification List
 *
 */
export const handleSelectAllNotificationEmail = (
  usersAll: Array<{
    id: string;
    display: string;
  }>,
  notification: ICreateRuleNotificationPayload,
  setNotification: any
) => {
  const allSelected = usersAll.length === notification.distributionUsers.length;

  setNotification({
    ...notification,
    distributionUsers: allSelected ? [] : usersAll,
    alertByEmail: !allSelected,
  });
};

/**
 *
 * @param value
 * @param rows
 * @param keyId
 * @returns void
 *
 * This function is used to return a boolean value
 * to know if the Relational Operator is the current active one
 *
 */
export const isOperatorActive = (
  value: string,
  rows: string[],
  keyId: number
) => {
  const operator = rows[keyId];
  if (value === operator) {
    return true;
  }
  return false;
};

/**
 *
 * @param rows
 * @returns void
 *
 * This function is used to return the
 * a boolean value
 *
 */
export const disableThenButton = (rows: string[], keyId: number = 0) => {
  return rows.includes('THEN') || keyId !== rows.length;
};

/**
 *
 * @param arr
 * @param index
 * @param newValue
 * @returns void
 *
 * This function is used to return replace an index value in an array
 *
 */
export function replaceArrayElement<T>(
  arr: T[],
  index: number,
  newValue: T
): T[] {
  if (index < 0 || index >= arr.length) {
    throw new Error('Index out of range');
  }
  arr[index] = newValue;
  return arr;
}

/**
 * Returns the duration description based on the condition type.
 * @param {Object} condition - The condition object.
 * @returns {string} - The duration description.
 */
const getDurationDescription = (condition: RuleCondition) => {
  if (condition.duration === 'instantaneous') {
    return 'instantaneously';
  }

  if (condition.duration === 'duration') {
    return `${condition.durationInfractionCount} times in ${condition.durationTimeframe} ${condition.durationTimeframeUnit}`;
  }

  if (condition.duration === 'continuous') {
    return `continuously over ${condition.durationTimeframe} ${condition.durationTimeframeUnit}`;
  }

  return '';
};

/**
 * Processes the array of conditions and returns an array of formatted strings.
 * @returns {Array} - An array of formatted strings.
 */
export const formatConditions = (
  conditions: RuleCondition[],
  unitSystem: string
) => {
  const formattedConditions = conditions.map((item, index) => {
    let logicalOperator = '';

    if (index === 0) {
      logicalOperator = 'If';
    } else {
      logicalOperator = item.logicalOperator;
    }

    logicalOperator = logicalOperator
      ? capitalizeFirstLetter(logicalOperator)
      : '';
    const convertedUnit =
      unitSystem === 'imperial'
        ? UNITS.find(
            (unitObj) =>
              unitObj.id === item.unit || unitObj.imperial === item.unit
          )?.imperial
        : item.unit;

    const method = UNITS.find((unitObj) => unitObj.id === item.unit)?.method;
    let convertedValue = item.value;
    if (method) {
      convertedValue = convertBasedOnUnitSystem(method, item.value, unitSystem);
    }

    const bodyIntro = `${item.tagName} ${item.relationalOperator} ${convertedValue} ${convertedUnit}`;
    const durationBody = getDurationDescription(item);

    return `${logicalOperator} ${bodyIntro} ${durationBody}`;
  });

  const conditionsString = formattedConditions.join(', ');

  return conditionsString;
};

/**
 *
 * @param rows
 * @returns void
 *
 * This function is used to return the
 * a boolean value
 *
 */
export const getModalHeaderStyle = (disabledPages: number[], page: number) => {
  let style = defaultTabStyle;
  if (disabledPages.includes(page)) {
    style = disabledTabStyle;
  }
  return style;
};

/**
 * Handles the selection of a single value for a given attribute and updates the state accordingly.
 * @param event - The event object containing the selected value.
 * @param state - The current state object.
 * @param setState - The function to update the state.
 * @param attribute - The attribute to update in the state.
 */
export const handleSelectSingle = (
  event: { target: { value: any } },
  state: any,
  setState: React.Dispatch<React.SetStateAction<any>>,
  attribute: string,
  maxSelection: number = Infinity,
  atLeastOneSelected?: boolean
) => {
  const selectedValue = event.target.value;

  if (!selectedValue?.length) {
    const newState = {
      ...state,
    };

    newState[attribute] = [];

    setState({
      ...newState,
    });

    return;
  }

  const selectedItem = JSON.parse(selectedValue[selectedValue.length - 1]);
  const items = [...state[attribute]];
  const idx = items.findIndex(({ id }) => id === selectedItem.id);

  if (idx > -1) {
    items.splice(idx, 1);
  } else {
    items.push(selectedItem);
    if (items.length > maxSelection) {
      return;
    }
  }

  const newState = {
    ...state,
  };

  newState[attribute] = items;

  if (atLeastOneSelected && items.length < 1) {
    return;
  }
  setState({
    ...newState,
  });
};

export const handleSelectSingleModel = (
  event: { target: { value: any } },
  state: any,
  setState: React.Dispatch<React.SetStateAction<any>>,
  attribute: string,
  maxSelection: number = Infinity,
  atLeastOneSelected?: boolean,
  defaultItems?: any[]
) => {
  const selectedValue = event.target.value;

  const getDefaultSelectedItems = () => {
    if (defaultItems && defaultItems.length > 0) {
      // If defaultItems (model) is provided and not empty, use it
      return defaultItems;
    } else if (state[attribute] && Array.isArray(state[attribute])) {
      // If defaultItems is empty, select all items in the current state
      return state[attribute];
    }
    return [];
  };

  let items = getDefaultSelectedItems();

  if (selectedValue?.length) {
    const selectedItem = JSON.parse(selectedValue[selectedValue.length - 1]);
    const idx = items.findIndex(
      ({ id }: { id: string }) => id === selectedItem.id
    );

    if (idx > -1) {
      // If item is already selected, remove it
      items = items.filter(({ id }: { id: string }) => id !== selectedItem.id);
    } else {
      // If item is not selected, add it
      items.push(selectedItem);
      if (items.length > maxSelection) {
        return; // Don't update if we exceed maxSelection
      }
    }
  }

  const newState = {
    ...state,
    [attribute]: items,
  };

  if (atLeastOneSelected && items.length < 1) {
    return; // Don't update if we need at least one selection and have none
  }

  setState(newState);
};

/**
 * Handles the selection of a single value for a given attribute and updates the state accordingly.
 * @param option - The option array containing the selected value.
 * @param state - The current state object.
 * @param setState - The function to update the state.
 * @param attribute - The attribute to update in the state.
 */
export const handleSelectSingleForGroupedSelector = (
  option: TGroupedMultiSelectOptions,
  state: any,
  setState: React.Dispatch<React.SetStateAction<any>>,
  attribute: string,
  maxSelection: number = Infinity,
  atLeastOneSelected?: boolean,
  saveToTagsCache?: boolean
) => {
  const selectedValue = option;
  if (!selectedValue?.length) {
    const newState = {
      ...state,
    };

    newState[attribute] = [];

    setState({
      ...newState,
    });

    return;
  }

  const selectedItem = selectedValue[selectedValue.length - 1];
  const items = [...state[attribute]];
  const idx = items.findIndex(({ id }) => id === selectedItem.id);

  if (idx > -1) {
    items.splice(idx, 1);
  } else {
    items.push(selectedItem);
    if (items.length > maxSelection) {
      return;
    }
  }

  const newState = {
    ...state,
  };

  newState[attribute] = items;

  if (atLeastOneSelected && items.length < 1) {
    return;
  }
  setState({
    ...newState,
  });

  if (saveToTagsCache) {
    sessionStorage.setItem(
      'selectedVehicleStatusTags',
      JSON.stringify({ ...newState })
    );
  }
};

/**
 * Handles the selection of all items for a given attribute and updates the state accordingly.
 * @param itemsAll - An array of passed in group items.
 * @param state - The current state object.
 * @param setState - The function to update the state.
 * @param attribute - The attribute to update in the state.
 */
export const handleSelectAllForGroupedSelector = (
  itemsAll: Array<{
    id: string;
    display: string;
  }>,
  state: any,
  setState: React.Dispatch<React.SetStateAction<any>>,
  attribute: string,
  allSelected: boolean,
  isObjectTagged = false,
  saveToTagsCache?: boolean
) => {
  if (isObjectTagged) {
    const currItems = state;
    const filteredValue = allSelected
      ? currItems.filter(
          (item: any) => !itemsAll.some((option) => option.id === item.id)
        )
      : itemsAll;

    setState([...filteredValue]);
    sessionStorage.setItem(
      'assetSelectedTags',
      JSON.stringify([...filteredValue])
    );
    return null;
  }

  const currItems = state[attribute];
  const filteredValue = allSelected
    ? currItems.filter(
        (item: any) => !itemsAll.some((option) => option.id === item.id)
      )
    : itemsAll;

  const newState = {
    ...state,
  };
  newState[attribute] = filteredValue;

  setState({
    ...newState,
  });

  if (saveToTagsCache) {
    sessionStorage.setItem(
      'selectedVehicleStatusTags',
      JSON.stringify({ ...newState })
    );
  }
};

/**
 * Handles the selection of all items for a given attribute and updates the state accordingly.
 * @param itemsAll - An array of all available items.
 * @param state - The current state object.
 * @param setState - The function to update the state.
 * @param attribute - The attribute to update in the state.
 */
export const handleSelectAll = (
  itemsAll: Array<{
    id: string;
    display: string;
  }>,
  state: any,
  setState: React.Dispatch<React.SetStateAction<any>>,
  attribute: string
) => {
  const currItems = state[attribute];

  // if all items are selected, remove all
  if (itemsAll.length === currItems.length) {
    const newState = {
      ...state,
    };
    newState[attribute] = [];
    setState({
      ...newState,
    });

    return;
  }

  // if not all items are selected, add all
  const newState = {
    ...state,
  };
  newState[attribute] = itemsAll;

  setState({
    ...newState,
  });
};

/**
 * Removes duplicates from an array of SelectOptions based on the 'id' property.
 * @param arr - An array of SelectOptions objects.
 * @returns An array of SelectOptions with unique 'id' values.
 */
export const removeDuplicatesById = (arr: SelectOptions[]): SelectOptions[] => {
  const idMap: Record<string, SelectOptions> = {};

  arr.forEach((obj) => {
    const id = obj.id;
    if (idMap[id]) return;

    idMap[id] = obj;
  });

  return Object.values(idMap);
};

/**
 * Returns an array of initial asset types as select options based on the provided assets.
 * @param assets - An array of Asset objects.
 * @returns An array of select options for initial asset types.
 */
export const getInitialAssetTypes = (assets: Asset[]): SelectOptions[] => {
  const filteredAssets = assets.map((asset) => {
    return {
      id: asset.assetType,
      display: resolveAssetTypeDisplay(asset.assetType),
    };
  });

  const assetOptions = removeDuplicatesById(filteredAssets);
  return assetOptions;
};

/**
 * Returns an array of select options for asset makes based on the provided parameters.
 * @param assets - An array of Asset objects.
 * @param assetType - The asset type to filter by.
 * @returns An array of select options for asset makes.
 */
export const getAssetMakeOptions = (
  assets: Asset[],
  assetType: string
): SelectOptions[] => {
  const filteredAssetMake = assets
    .filter((asset) => asset.assetType === assetType)
    .map((asset) => {
      return {
        id: asset.make,
        display: asset.make,
      };
    });
  const makeOptions = removeDuplicatesById(filteredAssetMake);
  return makeOptions;
};

/**
 * Returns an array of select options for asset models based on the provided parameters.
 * @param assets - An array of Asset objects.
 * @param assetType - The asset type to filter by.
 * @param assetMake - An array of select options for asset make.
 * @returns An array of select options for asset models.
 */
export const getAssetModelOptions = (
  assets: Asset[],
  assetType: string,
  assetMake: SelectOptions[]
): SelectOptions[] => {
  const assetMakeList = assetMake.map((assetMake) => {
    return assetMake.id;
  });

  let filteredAssets = assets.filter((asset) => asset.assetType === assetType);

  if (assetMakeList.length) {
    filteredAssets = filteredAssets.filter((asset) =>
      assetMakeList.includes(asset.make)
    );
  }

  const assetModels = filteredAssets.map((asset) => {
    return {
      id: asset.model,
      display: asset.model,
    };
  });
  const modelOptions = removeDuplicatesById(assetModels);
  return modelOptions;
};

/**
 * Returns an array of select options for asset models based on the provided parameters.
 * @param assets - An array of Asset objects.
 * @param assetType - The asset type to filter by.
 * @param assetMake - An array of select options for asset make.
 * @returns An array of select options for asset models.
 */
export const getAssetListOptions = (
  assets: Asset[],
  assetType: string,
  assetModel: SelectOptions[]
): SelectOptions[] => {
  const assetModelList = assetModel.map((assetModel) => {
    return assetModel.id;
  });

  let filteredAssets = assets.filter((asset) => asset.assetType === assetType);

  if (assetModelList.length) {
    filteredAssets = filteredAssets.filter((asset) =>
      assetModelList.includes(asset.model)
    );
  }

  const assetList = filteredAssets.map((asset) => {
    return {
      id: asset.device.deviceId,
      display: asset.bumperNumber,
    };
  });
  const listOptions = removeDuplicatesById(assetList);
  return listOptions;
};

export const getCurrentRuleMakeModel = (
  selectedAssets: SelectOptions[],
  assets: Asset[]
) => {
  const assetsSelected = assets.filter((asset) => {
    return selectedAssets.some(
      (selectedAsset) => selectedAsset.id === asset.device.deviceId
    );
  });
  const make: SelectOptions[] = [];
  const model: SelectOptions[] = [];
  assetsSelected.map((asset) => {
    if (!make.some((item) => item.id === asset.make)) {
      make.push({ id: `${asset.make}`, display: `${asset.make}` });
    }

    if (!model.some((item) => item.id === asset.model)) {
      model.push({ id: `${asset.model}`, display: `${asset.model}` });
    }

    return null;
  });
  return { make, model };
};
