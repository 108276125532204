import ProductionAPI from 'api/production.api';
import {
  setTotalFleetAssets,
  setTotalFleetSummary,
  setTotalFleetDetails,
  setIsLoading,
  setFleetPeriodSummary,
  setSuggestions,
} from 'store/production.slice';

import { BaseHandler } from './base.handler';

export default class ProductionHandler extends BaseHandler {
  private readonly api: ProductionAPI;

  constructor() {
    super();

    this.api = new ProductionAPI();
  }

  async get(
    range: string,
    startDate: string,
    endDate: string,
    shouldSendDate: boolean,
    initialRun: boolean,
    assetType: string
  ): Promise<any> {
    let stateChanges: any = {
      isLoadingFleetAssets: true,
      isLoadingFleetSummary: true,
      isLoadingSuggestions: true,
    };

    if (initialRun) {
      stateChanges = {
        ...stateChanges,
        isLoadingFleetDetails: true,
        isLoadingFleetPeriodSummary: true,
      };
    }
    this.dispatch(setIsLoading(stateChanges));

    try {
      // Fire off all the API calls without awaiting them
      const fleetSummaryPromise = this.api.getTotalFleetSummary(
        range,
        startDate,
        endDate,
        shouldSendDate
      );
      const fleetAssetsPromise = this.api.getTotalFleet(
        range,
        startDate,
        endDate,
        shouldSendDate
      );

      const suggestionsPromise = this.api.getSuggestions(
        range,
        startDate,
        shouldSendDate,
        assetType
      );

      let fleetDetailsPromise, fleetPeriodPromise;
      if (initialRun) {
        fleetDetailsPromise = this.api.getTotalFleetProduction();
        fleetPeriodPromise = this.api.getFleetPeriodSummary();
      }

      if (initialRun) {
        fleetDetailsPromise
          ?.then((totalFleetDetails) => {
            this.dispatch(
              setTotalFleetDetails(totalFleetDetails.totalFleetDetails)
            );
            this.dispatch(
              setIsLoading({
                isLoadingFleetDetails: false,
              })
            );
          })
          .catch(() => {
            this.handleError('Error fetching fleet details');
            this.dispatch(
              setIsLoading({
                isLoadingFleetDetails: false,
              })
            );
          });

        fleetPeriodPromise
          ?.then((fleetPeriod) => {
            this.dispatch(setFleetPeriodSummary(fleetPeriod.data));
            this.dispatch(
              setIsLoading({
                isLoadingFleetPeriodSummary: false,
              })
            );
          })
          .catch(() => {
            this.handleError('Error fetching fleet period summary');
            this.dispatch(
              setIsLoading({
                isLoadingFleetPeriodSummary: false,
              })
            );
          });
      }

      fleetSummaryPromise
        .then((fleetSummary) => {
          this.dispatch(setTotalFleetSummary(fleetSummary));
          this.dispatch(
            setIsLoading({
              isLoadingFleetSummary: false,
            })
          );
          this.dispatch(
            setIsLoading({
              isLoadingSuggestions: false,
            })
          );
        })
        .catch(() => {
          this.handleError('Error fetching fleet summary');
          this.dispatch(
            setIsLoading({
              isLoadingFleetSummary: false,
            })
          );
        });

      suggestionsPromise
        .then((suggestions) => {
          this.dispatch(setSuggestions(suggestions));
        })
        .catch(() => {
          this.handleError('Error fetching suggestions');
          this.dispatch(
            setIsLoading({
              isLoadingFleetSummary: false,
            })
          );
        });

      const assets = await fleetAssetsPromise
        .then((fleetAssets) => {
          this.dispatch(setTotalFleetAssets(fleetAssets.assets));
          this.dispatch(
            setIsLoading({
              isLoadingFleetAssets: false,
            })
          );
          return fleetAssets.assets;
        })
        .catch(() => {
          this.handleError('Error fetching fleet assets');
          this.dispatch(
            setIsLoading({
              isLoadingFleetAssets: false,
            })
          );
          return {};
        });

      return assets;
    } catch (_) {
      this.handleError('An error occurred while fetching production details');
      this.dispatch(
        setIsLoading({
          isLoadingFleetAssets: false,
          isLoadingFleetSummary: false,
          isLoadingFleetDetails: false,
          isLoadingFleetPeriodSummary: false,
        })
      );
    }

    return {};
  }
}
