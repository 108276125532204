import { useState } from 'react';
import {
  Typography,
  ListItem,
  ListItemText,
  Collapse,
  Button,
} from '@mui/material';
import { ThemePalette } from 'mui.theme';
import { useAppSelector } from 'store/hook';
import { convertSecondsWithUnitValues } from 'utils/helpers/dateTime';
import { getLabelWrtUnitSystem } from 'utils/helpers/unitSystem';
import { formatTripData } from 'views/Production/Hauler/component/utils';

interface Props {
  enableDarkTheme: boolean;
  data: any;
}

const SuggestionItems = ({ enableDarkTheme, data }: Props) => {
  const unitSystem = useAppSelector((state) => state.persistedReducer).customer
    .unitSystem;
  const {
    totalTripTime,
    possibleTimeSaved,
    averageDistance,
    totalDistance,
    totalPossibleDistanceSavings,
    outlierTripsBasedOnTime,
    tripsMoreThanAverageTime,
    tripsMoreThanAverageDistance,
    totalTrips,
  } = formatTripData(data, unitSystem);

  const [showOutliers, setShowOutliers] = useState(false);

  const convertTimeToSeconds = (timeStr: string) => {
    const [hours, minutes, seconds] = timeStr.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  };
  const totalFuelConsumedInOutliers = data.outlier_trips_based_on_time.reduce(
    (total: any, trip: { fuel_consumed: any }) =>
      Number(total) + Number(trip.fuel_consumed),
    0
  );
  const totalFuelConsumedInAllTrips = data.total_fuel_consumed;
  const groupedOutliers =
    outlierTripsBasedOnTime !== 'None'
      ? (outlierTripsBasedOnTime as string[]).reduce(
          (acc: any, item: string) => {
            const [trip, asset] = item.split(' for ');
            const [assetId, tripDetails] = asset.split(' with trip ');
            const [, tripTime] = tripDetails.split('of ');

            if (!acc[assetId]) {
              acc[assetId] = [];
            }

            acc[assetId].push({ trip, assetId, tripDetails, tripTime });
            return acc;
          },
          {}
        )
      : {};

  Object.keys(groupedOutliers).forEach((assetId) => {
    groupedOutliers[assetId].sort((a: any, b: any) => {
      return (
        convertTimeToSeconds(b.tripTime) - convertTimeToSeconds(a.tripTime)
      );
    });
  });

  return (
    <>
      <ListItem
        sx={{
          borderBottom: '1px solid #2d3b55',
          paddingBottom: '16px',
          display: 'flex',
          direction: 'row',
        }}
      >
        <ListItemText
          primary={
            <Typography
              sx={{ color: '#4A7BFA', fontSize: '14px', fontWeight: 'bold' }}
            >
              <span
                style={{
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                }}
              >
                Facts
              </span>
            </Typography>
          }
          secondary={
            <>
              <Typography
                sx={{ color: '#b0b7c3', fontSize: '12px', paddingTop: '5px' }}
              >
                <p>
                  Avg trip time for selected period was{' '}
                  {convertSecondsWithUnitValues(data.average_time_per_trip)}{' '}
                </p>
                <p>Total No of Trips: {totalTrips}</p>
                <p>Total Trip Time: {totalTripTime}</p>
                <p>
                  Total Distance: {totalDistance}{' '}
                  {getLabelWrtUnitSystem('KM', unitSystem).toLowerCase()}.
                </p>
                <p>
                  Avg. distance per trip: {averageDistance}{' '}
                  {getLabelWrtUnitSystem('KM', unitSystem).toLowerCase()}.
                </p>
              </Typography>
            </>
          }
        />
      </ListItem>

      <ListItem
        sx={{
          borderBottom: '1px solid #2d3b55',
          paddingBottom: '16px',
          display: 'flex',
          direction: 'row',
        }}
      >
        <ListItemText
          primary={
            <Typography
              sx={{ color: '#4A7BFA', fontSize: '14px', fontWeight: 'bold' }}
            >
              <span
                style={{
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                }}
              >
                Suggestions
              </span>
            </Typography>
          }
          secondary={
            <>
              <Typography
                sx={{ color: '#b0b7c3', fontSize: '12px', paddingTop: '5px' }}
              >
                <p>
                  * Possible Fuel savings:{' '}
                  {(
                    (totalFuelConsumedInOutliers /
                      totalFuelConsumedInAllTrips) *
                    100
                  ).toFixed(2)}
                  % ({data.outlier_trips_based_on_time?.length} outlier trips
                  consuming {Number(totalFuelConsumedInOutliers).toFixed(2)}{' '}
                  {getLabelWrtUnitSystem('L', unitSystem).toLowerCase()} of fuel
                  out of {Number(totalFuelConsumedInAllTrips).toFixed(2)}{' '}
                  {getLabelWrtUnitSystem('L', unitSystem).toLowerCase()} of
                  total fuel consumed over all trips)
                </p>
                <p>
                  * Possible Time Savings: {possibleTimeSaved} (
                  {(
                    (data.total_possible_time_savings_excluding_outliers_avg.toFixed(
                      2
                    ) /
                      data.total_time_in_trips.toFixed(2)) *
                    100
                  ).toFixed(2)}
                  %)
                </p>
                <p>
                  * Possible Distance Savings: {totalPossibleDistanceSavings}{' '}
                  {getLabelWrtUnitSystem('KM', unitSystem).toLowerCase()} (
                  {(
                    (data.total_possible_distance_savings_excluding_outliers_avg.toFixed(
                      2
                    ) /
                      data.total_distance.toFixed(2)) *
                    100
                  ).toFixed(2)}
                  %)
                </p>
                <p>
                  * Possible trip savings:{' '}
                  {Math.round(
                    data.total_possible_time_savings_excluding_outliers_avg /
                      data.average_time_per_trip
                  )}
                </p>
              </Typography>
            </>
          }
        />
      </ListItem>

      <ListItem>
        <Button
          onClick={() => {
            setShowOutliers(!showOutliers);
          }}
          sx={{ color: '#4A7BFA', fontSize: '12px', fontWeight: 'bold' }}
        >
          {showOutliers
            ? 'Outlier Trips: collapse'
            : 'To see Outlier Trips: Click to expand'}
        </Button>
      </ListItem>

      <Collapse in={showOutliers}>
        {Object.keys(groupedOutliers).map((assetId: string) => (
          <ListItem
            key={assetId}
            sx={{ borderBottom: '1px solid #2d3b55', paddingBottom: '16px' }}
          >
            <ListItemText
              primary={
                <Typography
                  sx={{
                    color: '#4A7BFA',
                    fontSize: '12px',
                    fontWeight: 'bold',
                  }}
                >
                  {assetId}
                </Typography>
              }
              secondary={
                <Typography
                  sx={{ color: '#b0b7c3', fontSize: '10px', paddingTop: '5px' }}
                >
                  {groupedOutliers[assetId].map((trip: any, index: number) => (
                    <p key={index}>
                      <span>{trip.trip}</span>

                      <span>
                        {' '}
                        took {trip.tripDetails.split('time of')?.[1]}
                      </span>
                    </p>
                  ))}
                </Typography>
              }
            />
          </ListItem>
        ))}
      </Collapse>
    </>
  );
};

export default SuggestionItems;
