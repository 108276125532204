import type { Asset } from 'types/asset.types';
import type { ITag } from 'types/tag.type';
import type { TagDisplay } from 'views/FleetOverview/AssetDisplay';

export const getAssetsCommmonTags = (assets: Asset[]) => {
  const commonAssetTags: TagDisplay[] = [];

  const mergedTags = getAssetTags(assets);

  const tagCountMap: Record<string, number> = {};

  // Count the occurrences of each tag name
  for (const tag of mergedTags) {
    const tagName = tag.tagName;
    tagCountMap[tagName] = (tagCountMap[tagName] || 0) + 1;
  }

  const commonTags: string[] = [];

  for (const tagName in tagCountMap) {
    if (tagCountMap[tagName] === assets.length) {
      commonTags.push(tagName);
    }
  }

  for (const commonTag of commonTags) {
    const tagUnit =
      mergedTags.find((item: ITag) => item.tagName === commonTag)?.unit ?? '';
    const tagAlias =
      mergedTags.find((item: ITag) => item.tagName === commonTag)?.tagAlias ??
      commonTag;

    commonAssetTags.push({
      id: commonTag,
      display: tagAlias,
      unit: tagUnit,
    });
  }
  commonAssetTags.sort((a, b) => a.display.localeCompare(b.display));
  return commonAssetTags;
};

export const getAssetTags = (assets: Asset[]) => {
  const mergedTags: ITag[] = [];

  for (const asset of assets) {
    mergedTags.push(...asset.device.tags);
  }
  return mergedTags;
};
