import dayjs from 'dayjs';

export const formatDate = (rawDate: string): string => {
  const date = new Date(rawDate);

  return date.toLocaleString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });
};

export const formatDateToString = (
  date: Date,
  format: string = 'MMM DD, YYYY'
): string => {
  return dayjs(date).format(format);
};

export const calculateTimeStampFromCurrentTime = (
  timestamp: number
): number => {
  const currentTimestamp = Date.now();
  return currentTimestamp - timestamp;
};

export const isWithinLast24Hours = (timestamp: number): boolean => {
  const difference = calculateTimeStampFromCurrentTime(timestamp);
  const millisecondsIn24Hours = 24 * 60 * 60 * 1000;

  return difference <= millisecondsIn24Hours;
};

export const todaysDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();

  return `${year}-${month < 10 ? `0${month}` : month}-${
    day < 10 ? `0${day}` : day
  }`;
};

//  format date to YYYY-MM-DD
export const formatDateShortener = (val: Date): string => {
  const date = new Date(val);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 because months are zero-based
  const day = date.getDate().toString().padStart(2, '0');

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export const convertSeconds = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = (seconds % 60).toFixed(0);

  return { hours, minutes, seconds: remainingSeconds };
};

export const convertSecondsWithUnitValues = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.ceil(seconds % 60);

  let timeString = '';

  if (hours > 0) {
    timeString += `${hours} hr `;
  }
  if (minutes > 0) {
    timeString += `${minutes} min `;
  }
  timeString += `${remainingSeconds} sec`;

  return timeString.trim(); // Remove any trailing spaces
};
