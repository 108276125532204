import { Typography } from '@mui/material';
import { ThemePalette } from 'mui.theme';
import type { TabProps } from 'react-tabs';
import { Tab } from 'react-tabs';
import './style.css';

interface Props extends TabProps {
  children: string;
  enableDarkTheme?: boolean;
}

// Custom Tab component without underline and with color adjustments for unselected state
const SWTab = ({ children, enableDarkTheme = false, ...rest }: Props) => {
  const { focus }: any = rest;
  const isSelected = rest.selected; // Check if the tab is selected

  return (
    <Tab {...rest}>
      <Typography
        variant="medium"
        sx={{
          color: isSelected
            ? enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.white
            : enableDarkTheme
            ? ThemePalette.typography.lightGrey
            : ThemePalette.typography.secondary,
          fontWeight: isSelected ? 'bold' : 'normal',
          padding: '8px 16px',
          transition: 'color 0.3s ease',
        }}
      >
        {children}
      </Typography>
    </Tab>
  );
};

SWTab.tabsRole = 'Tab'; // Required field for custom Tab

export default SWTab;
