import * as React from 'react';
import {
  List,
  ListItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  Typography,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';
import useTheme from '@mui/material/styles/useTheme';
import { useAppDispatch } from 'store/hook';
import { SWTextField } from 'components/Form/Input/SWTextField';
import { Selector } from 'components/Form/Selectors/Selector';
import { TIME_UNIT_OPTIONS, TAG_OPERATORS } from 'utils/enums';
import { modalInputFieldStyle } from 'styles/global.css';
import type { Asset } from 'types/asset.types';
import type {
  RuleCondition,
  RelationalOperators,
  DurationUnit,
  ConditionTrigger,
} from 'types/notification.types';
import { handleSetDurationUnit } from 'views/Settings/Rules/helpers/common';
import { appendRuleCondition, removeRuleCondition } from 'store/rule.slice';
import { validateYupSchema } from 'utils/validators/schema.validator';
import { createRuleConditionSchema } from 'utils/schemas/rule.schema';
import { RuleConditionButtonGroup } from './RuleConditionButtonGroup';
import type { ICreateRulePayload } from 'types/payloads/rule.payload.types';
import { getAssetsCommmonTags, getAssetTags } from 'utils/helpers/device';
import { ThemePalette } from 'mui.theme';
import { getLabelWrtUnitSystem } from 'utils/helpers/unitSystem';

interface Props {
  relationalOperator?: string;
  keyId?: number;
  deleteRow?: (index: number, id: string) => void;
  addRow: (index: number, relationalOperator: string) => void;
  rows: string[];
  newRule: ICreateRulePayload;
  assets: Asset[];
  enableDarkTheme: boolean;
  unitSystem: string;
}

export const RuleConditionComponent = ({
  relationalOperator,
  keyId,
  deleteRow,
  addRow,
  rows,
  newRule,
  assets,
  enableDarkTheme,
  unitSystem,
}: Props) => {
  const {
    palette: { bg },
  } = useTheme();

  let defaultRuleCondition = {
    id: Math.floor(Date.now() / 1000).toString(),
    duration: 'instantaneous' as ConditionTrigger,
    logicalOperator: relationalOperator ?? '',
    durationInfractionCount: 0,
    durationTimeframe: 0,
    durationTimeframeUnit: 'hours' as DurationUnit,
    tagName: '',
    relationalOperator: '>' as RelationalOperators,
    value: 0,
    unit: '',
  };

  if (newRule.conditions && newRule.conditions.length > (keyId ?? 0)) {
    defaultRuleCondition = newRule.conditions[keyId ?? 0];
  }

  const dispatch = useAppDispatch();
  const [condition, setCondition] = React.useState<RuleCondition>({
    ...defaultRuleCondition,
  });
  const [isValidForm, setFormValidity] = React.useState(false);

  const [inputValue, setInputValue] = React.useState(
    condition.value.toString()
  );

  const handleChangeTagValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    // check for valid number format (including decimals)
    const numberRegex = /^-?\d*\.?\d*$/;

    if (numberRegex.test(value) && (value.match(/\./g) ?? []).length <= 1) {
      setInputValue(value);

      if (
        value === '' ||
        value === '-' ||
        value === '.' ||
        !isTagExistsForAllAssets
      ) {
        // Handle empty input, just a minus sign, or just a decimal point
        setCondition({
          ...condition,
          value: 0,
        });
      } else {
        const floatValue = parseFloat(value);
        if (!isNaN(floatValue)) {
          setCondition({
            ...condition,
            value: floatValue,
          });
        }
      }
    }
  };

  // for tag values
  React.useEffect(() => {
    setInputValue(condition.value.toString());
  }, [condition.value]);

  const handleChangeDurationTimeframe = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let { value } = event.target;
    if (!value || isNaN(parseInt(value))) {
      value = '0';
    }

    setCondition({
      ...condition,
      durationTimeframe: parseInt(value),
    });
  };

  const handleChangeDurationInfractionCount = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let { value } = event.target;
    if (!value || isNaN(parseInt(value))) {
      value = '0';
    }
    setCondition({
      ...condition,
      durationInfractionCount: parseInt(value),
    });
  };

  // Validate form as user fills out fields
  React.useEffect(() => {
    const validateForm = async () =>
      await validateYupSchema(condition, createRuleConditionSchema);

    void validateForm().then((isValid) => {
      setFormValidity(isValid);
    });
  }, [condition]);

  React.useEffect(() => {
    setCondition({
      ...condition,
      logicalOperator: relationalOperator ?? '',
    });
  }, [relationalOperator]);

  React.useEffect(() => {
    if (condition.id || condition.conditionId) {
      // Only dispatch if we have a valid condition
      dispatch(appendRuleCondition(condition));
    }
  }, [condition, dispatch]);

  const ruleAssets = newRule.assets.reduce((result: Asset[], item) => {
    const matchedDevice = assets.find(
      ({ device }) => device.deviceId === item.id
    );
    if (matchedDevice) {
      result.push(matchedDevice);
    }
    return result;
  }, []);

  const assetTags = getAssetsCommmonTags(ruleAssets);
  let disableDeleteButton = true;
  if (keyId && deleteRow) {
    disableDeleteButton = false;
  }

  const isTagExistsForAllAssets = () => {
    if (condition.tagName.length > 0) {
      return assetTags.some((asset) => asset.id === condition.tagName);
    } else {
      return true;
    }
  };

  const conditionDisplayName = getAssetTags(assets).find((item: any) => {
    return (
      item.tagName === condition.tagName || item.tagAlias === condition.tagName
    );
  })?.tagAlias;

  return (
    <div style={{ paddingTop: keyId !== 1 ? '25px' : '0px' }}>
      {/** Rule Condition Form */}
      <Box
        key={keyId ?? condition.id}
        sx={{
          backgroundColor: enableDarkTheme
            ? ThemePalette.dark.boxBackground
            : bg.grey,
        }}
      >
        {/** Button to delete condition */}
        <IconButton
          disabled={disableDeleteButton}
          aria-label="delete"
          key={`${condition.id}-delete-button`}
          onClick={() => {
            if (keyId && deleteRow) {
              deleteRow(keyId - 1, condition.id);
              dispatch(removeRuleCondition(condition.id));
            }
          }}
          sx={{
            marginTop: '15px',
            marginLeft: '95%',
            maxWidth: '10px',
            color: 'button.primary',
          }}
        >
          <CancelIcon />
        </IconButton>
        {/** List Form to get Condition input */}
        {!isTagExistsForAllAssets() ? (
          <Typography
            key="small-warning-text"
            variant="small"
            sx={{
              margin: '0px 20px',
              color: '#e57373',
            }}
          >
            Please select a different sensor, as one or more assets may not have{' '}
            &ldquo;{conditionDisplayName}&ldquo;
          </Typography>
        ) : null}
        <List
          key={`${condition.id}-data-group-list`}
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: 0,
          }}
        >
          <ListItem
            key={`${condition.id}-data-list-if`}
            style={{
              maxWidth: '300px',
              marginRight: '15px',
            }}
          >
            <span
              key={`${condition.id}-data-list-span`}
              style={{
                marginRight: '5px',
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
            >
              If
            </span>
            {/* Tag Information Type Selector */}

            <Selector
              key={`${condition.id}-tag-information-selector`}
              value={condition.tagName}
              onChange={(event) => {
                const selectedAssetTag = assetTags.find(
                  (assetTag) => assetTag.id === event.target.value.toString()
                );

                const selectedAssetTagUnit = selectedAssetTag?.unit ?? '';

                setCondition({
                  ...condition,
                  tagName: event.target.value.toString(),
                  unit: getLabelWrtUnitSystem(selectedAssetTagUnit, unitSystem),
                });
              }}
              selectorOptions={assetTags}
              selectSx={{
                minWidth: '300px',
                maxWidth: '300px',
                marginRight: '10px',
              }}
              enableDarkTheme={enableDarkTheme}
            />
          </ListItem>
          {isTagExistsForAllAssets() && (
            <>
              <ListItem
                key={`${condition.id}-data-list-selector`}
                style={{
                  maxWidth: '100px',
                  marginRight: '10px',
                  marginLeft: '10px',
                }}
              >
                {/* Tag Comparison Selector */}
                <Selector
                  key={condition.id + 'tag-comparison-selector'}
                  value={condition.relationalOperator}
                  onChange={(event) => {
                    setCondition({
                      ...condition,
                      relationalOperator:
                        event.target.value.toString() as RelationalOperators,
                    });
                  }}
                  selectorOptions={TAG_OPERATORS}
                  selectSx={{
                    minWidth: '100px',
                    maxWidth: '100px',
                    marginRight: '10px',
                  }}
                  enableDarkTheme={enableDarkTheme}
                />
              </ListItem>
              <ListItem key={`${condition.id}-data-selector-2`}>
                {/* Rule Value TextField */}
                <SWTextField
                  key={`${condition.id}-"ruleValue"`}
                  name="ruleValue"
                  placeholder="Enter Value"
                  TextFieldProps={{
                    name: 'ruleValue',
                    value: inputValue,
                    onChange: handleChangeTagValue,
                    InputProps: {
                      sx: {
                        ...modalInputFieldStyle,
                        minWidth: '120px',
                        maxWidth: '120px',
                        height: '28px',
                        minHeight: '28px',
                        marginTop: '0px',
                      },
                    },
                  }}
                  enableDarkTheme={enableDarkTheme}
                />
                <span
                  key={`${condition.id}-data-selector-unit-span`}
                  style={{
                    marginLeft: '5px',
                    color: enableDarkTheme
                      ? ThemePalette.typography.white
                      : ThemePalette.typography.black,
                  }}
                >
                  {getLabelWrtUnitSystem(defaultRuleCondition.unit, unitSystem)}
                </span>
              </ListItem>
            </>
          )}
        </List>
        {isTagExistsForAllAssets() && (
          <RadioGroup
            key={`${condition.id}-instantaneous-radio`}
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="instantaneous"
            name="radio-buttons-group"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setCondition({
                ...condition,
                duration: event.target.value.toString() as ConditionTrigger,
              });
            }}
          >
            <List key={`${condition.id}-radio-button-setting`}>
              <ListItem key={`${condition.id}-instantaneous-list-item`}>
                <FormControlLabel
                  key={`${condition.id}-instantaneous`}
                  value="instantaneous"
                  control={
                    <Radio
                      key={`${condition.id}-radio-instataneous`}
                      sx={{
                        color: enableDarkTheme
                          ? ThemePalette.typography.cornflowerBlue
                          : ThemePalette.typography.primary,
                        '&.Mui-checked': {
                          color: enableDarkTheme
                            ? ThemePalette.typography.cornflowerBlue
                            : ThemePalette.typography.primary,
                        },
                      }}
                    />
                  }
                  label="Instantaneously"
                  checked={condition.duration === 'instantaneous'}
                  sx={{
                    color: enableDarkTheme
                      ? ThemePalette.typography.white
                      : ThemePalette.typography.black,
                  }}
                />
              </ListItem>
              <ListItem key={`${condition.id}-number-of-occurence-list-item`}>
                <FormControlLabel
                  key={`${condition.id}-duration`}
                  value="duration"
                  control={
                    <Radio
                      key={`${condition.id}-radio-duration`}
                      sx={{
                        color: enableDarkTheme
                          ? ThemePalette.typography.cornflowerBlue
                          : ThemePalette.typography.primary,
                        '&.Mui-checked': {
                          color: enableDarkTheme
                            ? ThemePalette.typography.cornflowerBlue
                            : ThemePalette.typography.primary,
                        },
                      }}
                    />
                  }
                  label=""
                  checked={condition.duration === 'duration'}
                />
                <SWTextField
                  key={`${condition.id}-duration-infraction-count`}
                  name="durationInfractionCount"
                  placeholder="Enter Value"
                  TextFieldProps={{
                    name: 'durationInfractionCount',
                    onChange: handleChangeDurationInfractionCount,
                    value:
                      condition.duration === 'duration'
                        ? condition.durationInfractionCount
                        : 0,
                    disabled: !(condition.duration === 'duration'),
                    InputProps: {
                      sx: {
                        ...modalInputFieldStyle,
                        minWidth: '120px',
                        maxWidth: '120px',
                        height: '28px',
                        minHeight: '28px',
                        marginTop: '0px',
                      },
                    },
                  }}
                  enableDarkTheme={enableDarkTheme}
                />
                <span
                  key={`${condition.id}-span-times-in`}
                  style={{
                    marginLeft: '2px',
                    fontSize: '12px',
                    marginRight: '2px',
                    color: enableDarkTheme
                      ? ThemePalette.typography.white
                      : ThemePalette.typography.black,
                  }}
                >
                  times in
                </span>
                <SWTextField
                  key={`${condition.id}-duration-time-frame`}
                  name="durationTimeframe"
                  placeholder="Enter Value"
                  TextFieldProps={{
                    name: 'durationTimeframe',
                    onChange: handleChangeDurationTimeframe,
                    disabled: !(condition.duration === 'duration'),
                    value:
                      condition.duration === 'duration'
                        ? condition.durationTimeframe
                        : 0,
                    InputProps: {
                      sx: {
                        ...modalInputFieldStyle,
                        minWidth: '120px',
                        maxWidth: '120px',
                        height: '28px',
                        minHeight: '28px',
                        marginTop: '0px',
                        marginRight: '2px',
                      },
                    },
                  }}
                  enableDarkTheme={enableDarkTheme}
                />

                <Selector
                  key={`${condition.id}-duration-time-frame-unit`}
                  value={condition.durationTimeframeUnit}
                  disabled={!(condition.duration === 'duration')}
                  onChange={(event) => {
                    const value = event.target.value.toString() as DurationUnit;
                    handleSetDurationUnit(value, condition, setCondition);
                  }}
                  selectorOptions={TIME_UNIT_OPTIONS}
                  selectSx={{
                    minWidth: '130px',
                    maxWidth: '130px',
                    marginRight: '10px',
                  }}
                  enableDarkTheme={enableDarkTheme}
                />
              </ListItem>
              <ListItem key={`${condition.id}-radio-continuous-list-item`}>
                <FormControlLabel
                  key={`${condition.id}-continuous`}
                  value={'continuous'}
                  control={
                    <Radio
                      key={`${condition.id}-radio-continuous`}
                      sx={{
                        color: enableDarkTheme
                          ? ThemePalette.typography.cornflowerBlue
                          : ThemePalette.typography.primary,
                        '&.Mui-checked': {
                          color: enableDarkTheme
                            ? ThemePalette.typography.cornflowerBlue
                            : ThemePalette.typography.primary,
                        },
                      }}
                    />
                  }
                  label="Continuously over"
                  checked={condition.duration === 'continuous'}
                  sx={{
                    color: enableDarkTheme
                      ? ThemePalette.typography.white
                      : ThemePalette.typography.black,
                  }}
                />
                <SWTextField
                  key={`${condition.id}-continuousValue`}
                  name="continuousValue"
                  placeholder="Enter Value"
                  TextFieldProps={{
                    name: 'continuousDurationValue',
                    onChange: handleChangeDurationTimeframe,
                    disabled: !(condition.duration === 'continuous'),
                    value:
                      condition.duration === 'continuous'
                        ? condition.durationTimeframe
                        : 0,
                    InputProps: {
                      sx: {
                        ...modalInputFieldStyle,
                        minWidth: '120px',
                        maxWidth: '120px',
                        height: '28px',
                        minHeight: '28px',
                        marginTop: '0px',
                        marginRight: '2px',
                      },
                    },
                  }}
                  enableDarkTheme={enableDarkTheme}
                />
                <Selector
                  key={`${condition.id}-duration-time-frame-unit`}
                  value={condition.durationTimeframeUnit}
                  disabled={!(condition.duration === 'continuous')}
                  onChange={(event) => {
                    const value = event.target.value.toString() as DurationUnit;
                    handleSetDurationUnit(value, condition, setCondition);
                  }}
                  selectorOptions={TIME_UNIT_OPTIONS}
                  selectSx={{
                    minWidth: '130px',
                    maxWidth: '130px',
                    marginRight: '10px',
                  }}
                  enableDarkTheme={enableDarkTheme}
                />
              </ListItem>
            </List>
          </RadioGroup>
        )}
      </Box>

      {/** Rule Condition RelationalOperator Div for adding new Conditions */}
      <div
        key={`${condition.id}-operator-button-div`}
        style={{
          marginTop: '10px',
          textAlign: 'center',
        }}
      >
        <RuleConditionButtonGroup
          isValidForm={isValidForm}
          rows={rows}
          addRow={addRow}
          keyId={keyId}
        />
      </div>
    </div>
  );
};
