import { Box } from '@mui/material';
import dayjs from 'dayjs';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Rectangle,
  ReferenceLine,
} from 'recharts';
import { FlexBox } from 'components/Containers/FlexBox';
import type { productionAsset } from 'types/production.types';
import { ThemePalette } from 'mui.theme';
import { getAssetChartDataSource } from '../../utils';
import { convertToTwoDecimalPlaces } from 'components/Charts/Tooltip';
import {
  getDataUnit,
  convertNanosecondsToMinutes,
} from 'views/Production/Hauler/component/utils';

interface Props {
  enableDarkTheme: boolean;
  data: productionAsset;
  selectedSource: string;
  unitSystem: string;
  height?: string;
}

const AssetItemChart = ({
  enableDarkTheme,
  data,
  selectedSource,
  unitSystem,
  height = '230px',
}: Props) => {
  const { date_stats: dateLoad } = data;

  const formattedDateLoad = Object.keys(dateLoad).map((date) => ({
    date,
    ...dateLoad[date],
    idling_duration: convertNanosecondsToMinutes(
      dateLoad[date].idling_duration
    ),
    productionValue: dateLoad[date].max_load_ton,
  }));

  let dataUnit = getDataUnit(selectedSource, unitSystem);
  dataUnit = dataUnit ? `(${dataUnit})` : '';

  const chartData = formattedDateLoad.map((date: any) => ({
    date: date.date,
    value: convertToTwoDecimalPlaces(
      date[getAssetChartDataSource(selectedSource)].toString()
    ),
  }));

  const formatTimestamp = (timestamp: number): string => {
    const date = dayjs(timestamp);
    const formattedDate = date.format('DD MMM');
    return formattedDate;
  };

  const average =
    chartData.reduce(
      (sum: number, entry: { value: string }) => sum + Number(entry.value),
      0
    ) / chartData.length;

  return (
    <Box sx={{ paddingTop: '10px' }}>
      <FlexBox
        sx={{
          maxWidth: 'inherit',
          height,
          paddingTop: '20px',
          marginRight: '10px',
          paddingBottom: '10px',
        }}
      >
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={700}
            height={300}
            data={chartData}
            margin={{
              top: 20,
              right: 100,
              left: 5,
              bottom: 5,
            }}
            barSize={30}
          >
            <CartesianGrid
              strokeDasharray="3 3"
              stroke={ThemePalette.dark.cartesianGrid}
              vertical={false}
            />
            <XAxis
              dataKey="date"
              tickFormatter={formatTimestamp}
              tick={{ fontSize: 10 }}
            />
            <YAxis
              tick={{ fontSize: 10 }}
              label={{
                value: `${selectedSource} ${dataUnit}`,
                angle: 270,
                position: 'insideLeft',
                fontSize: 10,
                textAnchor: 'middle',
                dy: 50,
              }}
            />
            <Tooltip
              cursor={{ fill: '#1B2B4A' }}
              wrapperStyle={{ outline: 'none', fontSize: '11px' }}
              contentStyle={{
                backgroundColor: 'transparent',
              }}
              itemStyle={{
                color: enableDarkTheme
                  ? ThemePalette.typography.wheat
                  : ThemePalette.typography.black,
              }}
            />
            <Bar
              dataKey="value"
              fill="#4A7BFA"
              activeBar={<Rectangle fill="pink" stroke="blue" />}
            />
            <ReferenceLine
              y={average}
              label={{
                value: `Avg ${average.toFixed(2)}  ${dataUnit}/Day`,
                position: 'right',
                fontSize: '8px',
              }}
              stroke="#4A7BFA"
              strokeDasharray="3 3"
              isFront
            />
          </BarChart>
        </ResponsiveContainer>
      </FlexBox>
    </Box>
  );
};

export default AssetItemChart;
