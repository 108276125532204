import { Grid, Box, Typography, Button } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ThemePalette } from 'mui.theme';
import MultiColorCircularLoader from 'views/Production/components/BoxLoader/MultiColorCircularLoader';
import CircularProgressChart from './CircularProgress';
import type { FleetDetailsItem } from 'types/utilization.types';
import { formatNumberToShortScale } from 'views/Production/Hauler/component/utils';
import { truncateTo2dp } from 'utils/helpers/general';
import { daysLeftInYear } from 'views/Utilization/utils';

interface Props {
  enableDarkTheme: boolean;
  unitSystem: string;
  isLoading: boolean;
  data: FleetDetailsItem;
}

const Details = ({ enableDarkTheme, unitSystem, isLoading, data }: Props) => {
  const noOfAssets = Number(data.number_of_assets);

  const target = 365 * 10.5 * noOfAssets; // in hours
  const value = Number(data.total_engine_working_minutes) / 60; // in hours
  const hasMetTarget = value > target;

  return (
    <Grid item lg={6} md={6} sm={12}>
      <Box
        sx={{
          padding: 2,
          backgroundColor: enableDarkTheme
            ? ThemePalette.dark.boxBackground
            : ThemePalette.light.toolBarBackground,
          borderRadius: '5px',
          border: '1px solid #2F445D',
          minHeight: '300px',
          maxHeight: '300px',
          overflowY: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                fontWeight: '600',
                fontSize: '13px',
              }}
            >
              Productive Hours
            </Typography>
            {/* <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              <Typography
                sx={{
                  fontSize: '12px',
                }}
              >
                Mine Production Plan:
              </Typography>
              <Button
                size="small"
                sx={{
                  border: '1px solid #4A7BFA',
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                  textTransform: 'none',
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  fontSize: '11px',
                }}
                startIcon={<FileUploadIcon />}
              >
                Upload
              </Button>
            </Box> */}
          </Box>
        </Box>
        {/** Content Box */}
        {isLoading ? (
          <MultiColorCircularLoader height="300px" />
        ) : (
          <Box
            sx={{
              paddingTop: '15px',
            }}
          >
            <Grid container>
              <Grid item lg={6} sm={12}>
                <Box
                  sx={{
                    display: 'flex',
                  }}
                >
                  <CircularProgressChart
                    enableDarkTheme={enableDarkTheme}
                    value={Number(data.total_engine_working_minutes)}
                    target={target * 60}
                    unitSystem={unitSystem}
                  />
                  <Box
                    sx={{
                      alignContent: 'center',
                      paddingLeft: '10px',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '11px',
                        color: enableDarkTheme
                          ? ThemePalette.typography.lightGrey
                          : ThemePalette.typography.black,
                      }}
                    >
                      YTD Target
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '11px',
                        color: enableDarkTheme
                          ? ThemePalette.typography.white
                          : ThemePalette.typography.black,
                        paddingBottom: '15px',
                      }}
                    >
                      {formatNumberToShortScale(
                        data.numOfDays * 10.5 * noOfAssets
                      )}{' '}
                      Hours
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '11px',
                        color: enableDarkTheme
                          ? ThemePalette.typography.lightGrey
                          : ThemePalette.typography.black,
                      }}
                    >
                      Annual Target
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '11px',
                        color: enableDarkTheme
                          ? ThemePalette.typography.white
                          : ThemePalette.typography.black,
                      }}
                    >
                      {formatNumberToShortScale(365 * 10.5 * noOfAssets)} Hours
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={6} sm={12}>
                <Box>
                  <Grid container>
                    <Grid item lg={6} sm={12}>
                      <Typography
                        sx={{
                          fontSize: '12px',
                          color: enableDarkTheme
                            ? ThemePalette.typography.lightGrey
                            : ThemePalette.typography.black,
                        }}
                      >
                        Annual Productivity Target
                      </Typography>

                      <Typography
                        sx={{
                          fontSize: '12px',
                          color: enableDarkTheme
                            ? ThemePalette.typography.white
                            : ThemePalette.typography.black,
                          paddingTop: '10px',
                        }}
                      >
                        {formatNumberToShortScale(365 * 10.5 * noOfAssets)}
                        <span
                          style={{
                            color: enableDarkTheme
                              ? ThemePalette.typography.lightGrey
                              : ThemePalette.typography.black,
                          }}
                        >
                          {' '}
                          Hours
                        </span>
                      </Typography>

                      <Typography
                        sx={{
                          paddingTop: '30px',
                          fontSize: '12px',
                          color: enableDarkTheme
                            ? ThemePalette.typography.lightGrey
                            : ThemePalette.typography.black,
                        }}
                      >
                        Rolling Daily Average
                      </Typography>

                      <Typography
                        sx={{
                          fontSize: '13px',
                          color: enableDarkTheme
                            ? ThemePalette.typography.white
                            : ThemePalette.typography.black,
                          paddingTop: '10px',
                        }}
                      >
                        {truncateTo2dp(
                          (365 * 10.5 * noOfAssets) / daysLeftInYear()
                        )}
                        <span
                          style={{
                            color: enableDarkTheme
                              ? ThemePalette.typography.lightGrey
                              : ThemePalette.typography.black,
                          }}
                        >
                          {' '}
                          Hours/Day
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item lg={6} sm={12}>
                      <Typography
                        sx={{
                          fontSize: '12px',
                          color: enableDarkTheme
                            ? ThemePalette.typography.lightGrey
                            : ThemePalette.typography.black,
                        }}
                      >
                        Remaining Days
                      </Typography>

                      <Typography
                        sx={{
                          fontSize: '13px',
                          color: enableDarkTheme
                            ? ThemePalette.typography.white
                            : ThemePalette.typography.black,
                          paddingTop: '10px',
                        }}
                      >
                        {daysLeftInYear()}
                      </Typography>

                      <Typography
                        sx={{
                          paddingTop: '30px',
                          fontSize: '13px',
                          color: enableDarkTheme
                            ? ThemePalette.typography.lightGrey
                            : ThemePalette.typography.black,
                        }}
                      >
                        Current Daily Average
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '13px',
                          color: enableDarkTheme
                            ? ThemePalette.typography.white
                            : ThemePalette.typography.black,
                          paddingTop: '10px',
                        }}
                      >
                        {formatNumberToShortScale(
                          truncateTo2dp(
                            Number(data.daily_average_working_minutes) /
                              noOfAssets /
                              60
                          )
                        )}
                        <span
                          style={{
                            color: enableDarkTheme
                              ? ThemePalette.typography.lightGrey
                              : ThemePalette.typography.black,
                          }}
                        >
                          {' '}
                          Hours/Day
                        </span>
                      </Typography>
                    </Grid>
                  </Grid>

                  {!hasMetTarget ? (
                    <></>
                  ) : (
                    <Box
                      sx={{
                        textAlign: 'center',
                        border: '1px solid #2F445F',
                        borderRadius: '5px',
                        marginTop: '15px',
                        padding: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '12px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <CheckCircleIcon
                          sx={{ color: '#029161', marginRight: '4px' }}
                        />
                        Avg Productive hours are on target
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    </Grid>
  );
};

export default Details;
