import { Grid, Typography } from '@mui/material';
import {
  Cell,
  RadialBarChart,
  RadialBar,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { Box } from '@mui/system';
import { ThemePalette } from 'mui.theme';
import { FlexBox } from 'components/Containers/FlexBox';
import MultiColorCircularLoader from 'views/Production/components/BoxLoader/MultiColorCircularLoader';
import TableList from './TableList';
import { truncateTo2dp } from 'utils/helpers/general';
import {
  convertNanosecondsToMinutes,
  formatNumberToShortScale,
} from 'views/Production/Hauler/component/utils';

interface Props {
  enableDarkTheme: boolean;
  title: string;
  isLoading: boolean;
  data: {
    productiveHours: number;
    productiveHoursDiff: number;
    idling: number;
    idlingDiff: number;
    noOfAssets: number;
    dateRange: number;
  };
}

const HoursBreakdown = ({ enableDarkTheme, title, isLoading, data }: Props) => {
  let maxHours = 10.5 * data.dateRange * data.noOfAssets;
  if (maxHours < data.productiveHours / 60) {
    maxHours = Number(truncateTo2dp(data.productiveHours / 60));
  }
  if (maxHours < data.idling / 60) {
    maxHours = Number(truncateTo2dp(data.productiveHours / 60));
  }

  const tableData = [
    {
      name: 'Productive',
      time: formatNumberToShortScale(truncateTo2dp(data.productiveHours / 60)),
      direction: data.productiveHoursDiff > 0 ? 'up' : 'down',
      color: '#FF0068',
      fill: '#FF0068',
      maxValue: maxHours,
    },
    {
      name: 'Idle',
      time: formatNumberToShortScale(
        truncateTo2dp(convertNanosecondsToMinutes(data.idling) / 60)
      ),
      direction: data.idlingDiff > 0 ? 'up' : 'down',
      color: '#FF9C2C',
      fill: '#FF9C2C',
      maxValue: maxHours,
    },
  ];

  const rechartList = [
    {
      name: 'Productive',
      time: truncateTo2dp(data.productiveHours / 60),
      direction: data.productiveHoursDiff > 0 ? 'up' : 'down',
      color: '#FF0068',
      fill: '#FF0068',
      maxValue: maxHours,
    },
    {
      name: 'Idle',
      time: truncateTo2dp(convertNanosecondsToMinutes(data.idling) / 60),
      direction: data.idlingDiff > 0 ? 'up' : 'down',
      color: '#FF9C2C',
      fill: '#FF9C2C',
      maxValue: maxHours,
    },
    {
      name: '',
      time: maxHours,
      color: enableDarkTheme ? '#162A46' : '#F3F4F6',
      fill: enableDarkTheme ? '#162A46' : '#F3F4F6',
    },
  ];

  return (
    <Grid item lg={6} md={6}>
      <Box
        sx={{
          padding: 2,
          backgroundColor: enableDarkTheme
            ? ThemePalette.dark.boxBackground
            : ThemePalette.light.toolBarBackground,
          borderRadius: '5px',
          border: '1px solid #2F445D',
          minHeight: '300px',
          maxHeight: '300px',
          overflowY: 'auto',
        }}
      >
        <Typography
          sx={{
            fontSize: '13px',
            fontWeight: '600',
          }}
        >
          {title}
        </Typography>
        <Box
          sx={{
            paddingTop: '10px',
          }}
        >
          {isLoading ? (
            <MultiColorCircularLoader height="300px" />
          ) : (
            <Grid container>
              <Grid item lg={6} sm={12}>
                <Box
                  sx={{
                    paddingTop: '20px',
                    minHeight: '200px',
                    maxHeight: '200px',
                  }}
                >
                  <FlexBox
                    sx={{
                      maxWidth: 'inherit',
                      height: '230px',
                      paddingTop: '20px',
                      marginRight: '10px',
                      paddingBottom: '10px',
                    }}
                  >
                    <ResponsiveContainer width="100%" height="100%">
                      <RadialBarChart
                        cx="50%"
                        cy="50%"
                        innerRadius="100%"
                        outerRadius="60%"
                        barSize={10}
                        data={rechartList}
                        startAngle={360}
                        endAngle={0}
                      >
                        <RadialBar background dataKey="time" cornerRadius={10}>
                          {rechartList.map((entry, index) => (
                            <Cell key={`cell-${index}`} opacity={1} />
                          ))}
                        </RadialBar>
                        <Legend
                          iconSize={8}
                          layout="vertical"
                          verticalAlign="middle"
                          wrapperStyle={{ fontSize: '10px' }}
                        />
                      </RadialBarChart>
                    </ResponsiveContainer>
                  </FlexBox>
                </Box>
              </Grid>
              <Grid item lg={6} sm={12}>
                <TableList enableDarkTheme={enableDarkTheme} data={tableData} />
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>
    </Grid>
  );
};

export default HoursBreakdown;
