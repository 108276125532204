import { Grid } from '@mui/material';
import { CustomTabPanel } from '../CustomPanel';
import DateRangeDiv from '../HeaderMenu/DateRangeDiv';
import { ThemePalette } from 'mui.theme';
import HaulerProductionDetails from './component/HaulerProductionDetails';
import type {
  productionLoadingStates,
  fleetData,
  productionAsset,
} from 'types/production.types';
import HaulerAnalyticsWrapper from './component/HaulerAnalyticsWrapper';
import AverageCycleTime from '../AllFleet/components/AverageCycleTime';
import {
  getHaulerSummary,
  getProductionAssetsByTypeMapping,
} from '../AllFleet/utils';
import FleetProductionChart from '../AllFleet/components/FleetProductionChart';
import AssetView from './component/HaulerAssetView';
import Suggestions from '../components/Suggestions/Suggestions';
import HaulerFuelConsumption from './component/FuelConsumption';

interface Props {
  value: number;
  enableDarkTheme: boolean;
  isSelected: string;
  setIsSelected: any;
  handleDateChange: any;
  startDate: any;
  endDate: any;
  yesterday: any;
  unitSystem: string;
  loadingStates: productionLoadingStates;
  assetOverview: any;
  data: fleetData;
}

const Hauler = ({
  value,
  enableDarkTheme,
  isSelected,
  setIsSelected,
  handleDateChange,
  startDate,
  endDate,
  yesterday,
  unitSystem,
  loadingStates,
  data,
  assetOverview,
}: Props) => {
  const haulersSummary: any = getHaulerSummary(data.summary);

  const haulerAssets: productionAsset[] = getProductionAssetsByTypeMapping(
    data.assets,
    'Haul Truck'
  );

  return (
    <>
      <CustomTabPanel value={value} index={1}>
        <div>
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
          >
            <HaulerProductionDetails
              enableDarkTheme={enableDarkTheme}
              isLoading={loadingStates.isLoadingFleetDetails}
              unitSystem={unitSystem}
              data={data.totalFleetDetails}
            />

            <FleetProductionChart
              enableDarkTheme={enableDarkTheme}
              data={data.fleetPeriodSummary}
              isLoading={loadingStates.isLoadingFleetPeriodSummary}
              unitSystem={unitSystem}
              title="Haulers Production"
            />
          </Grid>

          <DateRangeDiv
            enableDarkTheme={enableDarkTheme}
            isSelected={isSelected}
            setIsSelected={setIsSelected}
            handleDateChange={handleDateChange}
            startDate={startDate}
            endDate={endDate}
            yesterday={yesterday}
            ignoreYtd={value === 2}
          />

          <HaulerAnalyticsWrapper
            enableDarkTheme={enableDarkTheme}
            data={data.summary}
            isLoading={loadingStates.isLoadingFleetSummary}
            unitSystem={unitSystem}
            assetOverview={assetOverview}
            scoreData={data.score}
            chartData={data.summaryChart}
          />

          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            sx={{
              paddingTop: '15px',
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
          >
            <Grid item lg={9} sm={12} md={12}>
              <Grid container spacing={2} justifyContent="space-between">
                <AverageCycleTime
                  enableDarkTheme={enableDarkTheme}
                  title="Average Hauler Cycle Time"
                  data={haulersSummary}
                  isLoading={loadingStates.isLoadingFleetSummary}
                />
                <HaulerFuelConsumption
                  enableDarkTheme={enableDarkTheme}
                  title="Fuel Consumption"
                  isLoading={loadingStates.isLoadingFleetSummary}
                  unitSystem={unitSystem}
                />
              </Grid>
            </Grid>
            <Suggestions
              enableDarkTheme={enableDarkTheme}
              minHeight="300px"
              data={data.suggestions}
              isLoading={loadingStates.isLoadingSuggestions}
              startDate={startDate}
              isSelected={isSelected}
            />
          </Grid>

          <AssetView
            enableDarkTheme={enableDarkTheme}
            unitSystem={unitSystem}
            isLoading={loadingStates.isLoadingFleetAssets}
            data={haulerAssets}
            isSelected={isSelected}
          />
        </div>
      </CustomTabPanel>
    </>
  );
};

export default Hauler;
