import { EditCustomerContactModal } from './components/modals/EditCustomerContactModal';
import { useState } from 'react';

export const EditCustomerContactView = () => {
  const [isOperatorModalOpen, setIsOperatorModalOpen] = useState(true);

  return (
    <>
      <EditCustomerContactModal
        key={`${isOperatorModalOpen.toString()}-create-operator`}
        open={isOperatorModalOpen}
        handleAbort={() => {
          setIsOperatorModalOpen(false);
          window.history.back();
        }}
        title="Edit Contact Information"
      />
    </>
  );
};
