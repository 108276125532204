import dayjs from 'dayjs';

export function getPastDate(range: string) {
  const today = dayjs();

  switch (range) {
    case '1D':
      return today.subtract(1, 'day').format('YYYY-MM-DD');
    case '7D':
      return today.subtract(7, 'day').format('YYYY-MM-DD');
    case '14D':
      return today.subtract(14, 'day').format('YYYY-MM-DD');
    case '1m':
      return today.subtract(1, 'month').format('YYYY-MM-DD');
    case '1M':
      return today.subtract(1, 'month').format('YYYY-MM-DD');
    case '1Y':
      return today.subtract(1, 'year').format('YYYY-MM-DD');
    case 'YTD':
    case 'all':
      // YTD logic: start of the current year
      return today.startOf('year').format('YYYY-MM-DD');
    default:
      throw new Error('Invalid range format');
  }
}
