import { useState } from 'react';
import { Grid, Typography, Box } from '@mui/material';
import dayjs from 'dayjs';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Rectangle,
  Label,
  ReferenceLine,
} from 'recharts';
import { ThemePalette } from 'mui.theme';
import { FlexBox } from 'components/Containers/FlexBox';
import ProductionSelector from 'views/Production/components/Selector/Selector';
import type { fleetPeriodSummary } from 'types/production.types';
import { getFleetProduction } from '../utils';
import MultiColorCircularLoader from 'views/Production/components/BoxLoader/MultiColorCircularLoader';
import { convertToTwoDecimalPlaces } from 'components/Charts/Tooltip';

interface Props {
  enableDarkTheme: boolean;
  data: fleetPeriodSummary;
  isLoading: boolean;
  unitSystem: string;
  title: string;
}

const options = [
  'Last 7 Days',
  'Last 14 Days',
  'Last 4 Weeks',
  'Last 6 Months',
];

const FleetProductionChart = ({
  enableDarkTheme,
  data,
  isLoading,
  unitSystem,
  title,
}: Props) => {
  const [selectedChartSource, setSelectedChartSource] =
    useState<string>('Last 7 Days');

  let chartData: any = [];

  const selectedSource: any = data.find(
    (item) => item.time_range === getFleetProduction(selectedChartSource)
  );

  if (selectedSource) {
    chartData = Object.keys(selectedSource.daily_production).map((date) => ({
      date,
      value: convertToTwoDecimalPlaces(selectedSource.daily_production[date]),
    }));
  }

  const formatTimestamp = (timestamp: number): string => {
    const date = dayjs(timestamp);
    const formattedDate = date.format('DD MMM');
    return formattedDate;
  };

  const getHighestValue = (arr: any) => {
    const highestValue: number = arr.reduce((max: any, obj: any) => {
      const currentValue = Number(obj.value);
      return !isNaN(currentValue) && currentValue > max ? currentValue : max;
    }, -Infinity);

    return highestValue;
  };

  const average =
    chartData.reduce(
      (sum: number, entry: { value: string }) => sum + Number(entry.value),
      0
    ) / chartData.length;

  return (
    <Grid item lg={6} md={6} sm={12} xs={12}>
      <Box
        sx={{
          padding: 2,
          backgroundColor: enableDarkTheme
            ? ThemePalette.dark.boxBackground
            : ThemePalette.light.toolBarBackground,
          borderRadius: '5px',
          border: '1px solid #2F445D',
          minHeight: '300px',
          maxHeight: '300px',
          position: 'relative',
        }}
      >
        <Typography
          sx={{
            fontWeight: '600',
            fontSize: '13px',
          }}
        >
          {title}
        </Typography>

        <Box
          sx={{
            position: 'absolute',
            top: '16px',
            right: '16px',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <Typography
            sx={{
              fontSize: '12px',
              color: enableDarkTheme
                ? ThemePalette.typography.lightGrey
                : ThemePalette.typography.black,
            }}
          >
            Show
          </Typography>

          <ProductionSelector
            enableDarkTheme={enableDarkTheme}
            options={options}
            selected={selectedChartSource}
            setSelected={setSelectedChartSource}
            minWidth="150px"
          />
        </Box>

        {isLoading ? (
          <MultiColorCircularLoader height="300px" />
        ) : (
          <Box
            sx={{
              paddingTop: '15px',
            }}
          >
            <FlexBox
              sx={{
                maxWidth: 'inherit',
                height: '230px',
                paddingTop: '20px',
                marginRight: '10px',
                paddingBottom: '10px',
              }}
            >
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  width={700}
                  height={300}
                  data={chartData}
                  margin={{
                    top: 20,
                    right: 110,
                    left: 5,
                    bottom: 5,
                  }}
                  barSize={30}
                >
                  <CartesianGrid
                    strokeDasharray="3 3"
                    stroke={ThemePalette.dark.cartesianGrid}
                    vertical={false}
                  />
                  <XAxis
                    dataKey="date"
                    tickFormatter={formatTimestamp}
                    tick={{ fontSize: 10 }}
                  />
                  <YAxis
                    tick={{ fontSize: 10 }}
                    label={{
                      value: `Production (${
                        unitSystem === 'imperial' ? 'iTons' : 'Tons'
                      })`,
                      angle: 270,
                      position: 'insideLeft',
                      fontSize: 10,
                      textAnchor: 'middle',
                      dy: 50,
                    }}
                    domain={[0, getHighestValue(chartData)]}
                  />
                  <Label
                    style={{
                      textAnchor: 'middle',
                      fontSize: '11px',
                    }}
                    angle={270}
                    value={`Production (${
                      unitSystem === 'imperial' ? 'iTons' : 'Tons'
                    })`}
                  />
                  <Tooltip
                    cursor={{ fill: '#1B2B4A' }}
                    wrapperStyle={{ outline: 'none', fontSize: '11px' }}
                    contentStyle={{
                      backgroundColor: 'transparent',
                    }}
                    itemStyle={{
                      color: enableDarkTheme
                        ? ThemePalette.typography.wheat
                        : ThemePalette.typography.black,
                    }}
                  />
                  <Bar
                    dataKey="value"
                    fill="#4A7BFA"
                    activeBar={<Rectangle fill="pink" stroke="blue" />}
                  />
                  <ReferenceLine
                    y={average}
                    label={{
                      value: `Avg ${average.toFixed(2)}  ${
                        unitSystem === 'imperial' ? 'iTons' : 'Tons'
                      }/Day`,
                      position: 'right',
                      fontSize: '8px',
                    }}
                    stroke="#4A7BFA"
                    strokeDasharray="3 3"
                    isFront
                  />
                </BarChart>
              </ResponsiveContainer>
            </FlexBox>
          </Box>
        )}
      </Box>
    </Grid>
  );
};

export default FleetProductionChart;
