import React from 'react';
import { Typography, CircularProgress } from '@mui/material';
import type { CircularProgressProps } from '@mui/material';
import { Box, styled } from '@mui/system';
import { convertToTwoDecimalPlaces } from 'components/Charts/Tooltip';
import { formatNumberToShortScale } from 'views/Production/Hauler/component/utils';

interface BackgroundCircleProps extends CircularProgressProps {
  enableDarkTheme: boolean;
}

interface Props {
  enableDarkTheme: boolean;
  target: number;
  value: number;
  unitSystem: string;
}

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  color: '#4b76f3',
  '& .MuiCircularProgress-circle': {
    strokeLinecap: 'round',
  },
}));

const BackgroundCircle = styled(CircularProgress)<BackgroundCircleProps>(
  ({ theme, enableDarkTheme }) => ({
    color: enableDarkTheme ? '#2F445F' : '#E7EBEF',
  })
);

const ContentBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  color: '#9fb8db',
}));

const ProgressWrapper = styled(Box)({
  transform: 'rotate(-180deg)',
  borderRadius: '50%',
});

const CircularProgressChart: React.FC<Props> = ({
  enableDarkTheme,
  target,
  value,
  unitSystem,
}) => {
  let percentageValue = (value / target) * 100;

  if (percentageValue > 100) {
    percentageValue = 100;
  }

  return (
    <Box
      position="relative"
      display="inline-flex"
      sx={{
        borderRadius: '50%',
        padding: '5px',
      }}
    >
      <ProgressWrapper>
        <BackgroundCircle
          variant="determinate"
          value={100}
          size={150}
          thickness={3}
          enableDarkTheme={enableDarkTheme}
        />
        <StyledCircularProgress
          variant="determinate"
          value={percentageValue}
          size={150}
          thickness={3}
          sx={{
            position: 'absolute',
            left: 0,
            top: 0,
          }}
        />
      </ProgressWrapper>
      <ContentBox>
        <Typography
          component="div"
          color="inherit"
          sx={{
            fontSize: '12px',
          }}
        >
          YTD Actual
        </Typography>
        <Typography
          component="div"
          color="#4b76f3"
          sx={{
            fontSize: '24px',
            fontWeight: '600',
          }}
        >
          {formatNumberToShortScale(
            convertToTwoDecimalPlaces(value.toString())
          )}
        </Typography>
        <Typography
          component="div"
          color="inherit"
          sx={{
            fontSize: '12px',
          }}
        >
          {unitSystem === 'imperial' ? 'iTons' : 'Tons'}
        </Typography>
      </ContentBox>
    </Box>
  );
};

export default CircularProgressChart;
