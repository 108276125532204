import * as React from 'react';
import { BaseTableList } from 'components/AgGrid/AgGrid';
import { ListWrapper } from 'components/AgGrid/CellRenderer/ListView.styled';
import { type WorkOrdersSquare } from 'types/squares.type';
import { useAppSelector } from 'store/hook';

interface Props {
  workOrders: WorkOrdersSquare[];
}
export const WorkOrdersList: React.FC<Props> = ({ workOrders }) => {
  const customerName = useAppSelector(
    (state) => state.persistedReducer.customer.code
  );

  const rowData = React.useMemo(() => workOrders, [workOrders]);
  const [columnDefs] = React.useState([
    {
      field: 'date_of_orders',
      headerName: 'Date',
      maxWidth: 150,
      filter: 'agTextColumnFilter',
      sortable: true,
    },

    {
      field: 'work_order_number',
      headerName: 'Work Order #',
      maxWidth: 180,
      filter: 'agTextColumnFilter',
      sortable: true,
    },
    {
      field: 'work_order_status',
      headerName: 'Work Status',
      maxWidth: 150,
      hide: customerName === 'buz',
    },
    {
      field: 'asset_id',
      headerName: 'Asset ID',
      maxWidth: 150,
      filter: 'agTextColumnFilter',
      sortable: true,
    },
    {
      field: 'actions_performed',
      headerName: 'Action(s) Performed',
      width: 150,
      maxWidth: 250,
      cellStyle: { textAlign: 'center' },
    },
    {
      field: 'components_category',
      headerName: 'Component Category',
      maxWidth: 200,
      filter: 'agTextColumnFilter',
      sortable: true,
    },
    {
      field: 'sub_component_categories',
      headerName: 'Component Sub Category',
      maxWidth: 250,
      filter: 'agTextColumnFilter',
      sortable: true,
    },
    {
      field: 'description',
      headerName: 'Work Order Description',
      maxWidth: 600,
      tooltipField: 'description',
    },
  ]);

  // Filter function - adjust as needed
  const filterFunction = (node: any) => {
    // Replace this logic with the desired filtering logic
    return !!node.data?.isActive;
  };

  return (
    <ListWrapper>
      <BaseTableList
        rowData={rowData}
        columnDefs={columnDefs}
        filter={filterFunction}
        pagination={true}
        paginationPageSize={20}
      />
    </ListWrapper>
  );
};
