import { updateLoadingState } from 'store/app.slice';
import { BaseHandler } from '../base.handler';
import MaintenanceSquaresAPI from 'api/Squares/Maintenance/squares.api';
import {
  setSquares,
  setSquaresAssets,
} from 'store/SquaresStore/MaintenanceSquaresState.slice';
import { type ICreateSquaresPayload } from 'types/payloads/squares.payload.types';
import { useAppSelector } from 'store/hook';

export default class SquaresMaintenanceHandler extends BaseHandler {
  private readonly apiMaintenance: MaintenanceSquaresAPI;
  private readonly customer = useAppSelector((state) => state.persistedReducer)
    .customer.code;

  constructor() {
    super();
    this.apiMaintenance = new MaintenanceSquaresAPI();
  }

  async getSelectedAssetsPmExpenditures(
    selectedStartDate: Date,
    selectedEndDate: Date,
    assets: string[],
    sort: string,
    category: string
  ) {
    this.dispatch(updateLoadingState(true));
    try {
      const payload: ICreateSquaresPayload = {
        selectedStartDate,
        selectedEndDate,
        assets,
        sort,
        category,
        customerName: this.customer,
      };
      const squares = await this.apiMaintenance.getSelectedAssetsPmExpenditures(
        payload
      );

      this.dispatch(setSquares(squares.Items));
      this.handleSuccess();
      return squares;
    } catch (error: any) {
      let msg: string = '';

      // Extract the error message from AxiosErrors
      // TODO: Look into other errors that can be thrown
      if (error.isAxiosError) {
        msg = error.response.data.message as string;
      }

      this.handleError(
        'An error occured while retrieving the squares : ' + msg
      );
      return [];
    }
  }

  async getSelectedAssetsComponentPmCost(
    selectedStartDate: Date,
    selectedEndDate: Date,
    assets: string[],
    category: string,
    maintenanceType: string
  ) {
    this.dispatch(updateLoadingState(true));
    try {
      const payload: ICreateSquaresPayload = {
        selectedStartDate,
        selectedEndDate,
        assets,
        category,
        customerName: this.customer,
        maintenanceType,
      };
      const squares =
        await this.apiMaintenance.getSelectedAssetsComponentPmCost(payload);

      this.dispatch(setSquares(squares.Items));
      this.handleSuccess();
      return squares;
    } catch (error: any) {
      let msg: string = '';

      // Extract the error message from AxiosErrors
      // TODO: Look into other errors that can be thrown
      if (error.isAxiosError) {
        msg = error.response.data.message as string;
      }

      this.handleError(
        'An error occured while retrieving the squares : ' + msg
      );
      return [];
    }
  }

  async getSelectedAssetsPmVsCmCost(
    selectedStartDate: Date,
    selectedEndDate: Date,
    assets: string[],
    category: string
  ) {
    this.dispatch(updateLoadingState(true));
    try {
      const payload: ICreateSquaresPayload = {
        selectedStartDate,
        selectedEndDate,
        assets,
        category,
        customerName: this.customer,
      };
      const squares = await this.apiMaintenance.getSelectedAssetsPmVsCmCost(
        payload
      );

      this.dispatch(setSquares(squares.Items));
      this.handleSuccess();
      return squares;
    } catch (error: any) {
      let msg: string = '';

      // Extract the error message from AxiosErrors
      // TODO: Look into other errors that can be thrown
      if (error.isAxiosError) {
        msg = error.response.data.message as string;
      }

      this.handleError(
        'An error occured while retrieving the squares : ' + msg
      );
      return [];
    }
  }

  async getAllAssets(interval: string): Promise<[]> {
    this.dispatch(updateLoadingState(true));
    try {
      const payload: ICreateSquaresPayload = {
        interval,
        customerName: this.customer,
      };

      const response = await this.apiMaintenance.getAllAssets(payload);
      const squares = response.Items; // Assuming this is an array of square items

      this.dispatch(setSquaresAssets(squares));
      this.handleSuccess();
      return squares; // Return the squares so that they can be used by the caller
    } catch (error: any) {
      let msg: string = '';

      // Extract the error message from AxiosErrors
      if (error.isAxiosError) {
        msg = error.response.data.message as string;
      }

      this.handleError(
        'An error occured while retrieving the squares : ' + msg
      );

      // Return an empty array or throw an error depending on how you want to handle this
      return [];
    }
  }

  async getAllComponentsCategory(): Promise<[]> {
    this.dispatch(updateLoadingState(true));
    try {
      const response = await this.apiMaintenance.getAllComponentsCategory();
      const squares = response.Items; // Assuming this is an array of square items

      this.dispatch(setSquaresAssets(squares));
      this.handleSuccess();
      return squares; // Return the squares so that they can be used by the caller
    } catch (error: any) {
      let msg: string = '';

      // Extract the error message from AxiosErrors
      if (error.isAxiosError) {
        msg = error.response.data.message as string;
      }

      this.handleError(
        'An error occured while retrieving the squares : ' + msg
      );

      // Return an empty array or throw an error depending on how you want to handle this
      return [];
    }
  }
}
