import type { TripData, TripDetails } from 'types/production.types';
import { convertSecondsWithUnitValues } from 'utils/helpers/dateTime';
import { getLabelWrtUnitSystem } from 'utils/helpers/unitSystem';

export function getDataKeyResponse(type: string) {
  if (type === 'Production') {
    return 'productionValue';
  }

  if (type === 'Fuel') {
    return 'fuelValue';
  }

  if (type === 'Utilization') {
    return 'utilizationValue';
  }

  if (type === 'Idling') {
    return 'idlingValue';
  }

  return 'productionValue';
}

export function getDataUnit(type: string, unitSystem: string) {
  if (type === 'Production') {
    return unitSystem === 'imperial' ? 'iTons' : 'Tons';
  }

  if (type === 'Fuel') {
    return unitSystem === 'imperial' ? 'gal' : 'l';
  }

  if (type === 'Utilization') {
    return unitSystem === 'imperial' ? 'iTons/gal' : 'Tons/l';
  }

  if (type === 'Idling') {
    return 'mins';
  }
}

export function convertNanosecondsToMinutes(nanoseconds: any) {
  const seconds = nanoseconds / 1_000_000_000; // Convert nanoseconds to seconds
  const minutes = seconds / 60; // Convert seconds to minutes
  return minutes;
}

export function percentageLeft(current: any, target: any) {
  if (target === 0) return 0; // Avoid division by zero
  const left = ((target - current) / target) * 100;
  return left > 0 ? left : 0; // If current exceeds target, return 0
}

export function formatNumberToShortScale(value: number | string) {
  const num = Number(value);

  if (isNaN(num)) {
    return 'Invalid input';
  }

  if (num >= 1_000_000_000) {
    return (num / 1_000_000_000).toFixed(1) + 'b';
  } else if (num >= 1_000_000) {
    return (num / 1_000_000).toFixed(1) + 'm';
  } else if (num >= 1_000) {
    return (num / 1_000).toFixed(1) + 'k';
  } else {
    return num.toString();
  }
}

export function formatTripData(
  data: Partial<TripData>,
  unitSystem: string
): {
  totalTrips: number;
  totalTripTime: string;
  possibleTimeSaved: string;
  averageDistance: string;
  totalDistance: string;
  totalPossibleDistanceSavings: string;
  outlierTripsBasedOnTime: string | Array<string | null>;
  tripsMoreThanAverageTime: string | Array<string | null>;
  tripsMoreThanAverageDistance: string | Array<string | null>;
} {
  const totalTimeInHours = data.total_time_in_trips
    ? convertSecondsWithUnitValues(data.total_time_in_trips)
    : 'N/A';
  const totalPossibleTimeSavingsInHours =
    data.total_possible_time_savings_excluding_outliers_avg
      ? convertSecondsWithUnitValues(
          data.total_possible_time_savings_excluding_outliers_avg
        )
      : 'N/A';

  const tripsMoreThanAverageTime =
    data.trips_more_than_average_time &&
    data.trips_more_than_average_time.length > 0
      ? data.trips_more_than_average_time
          .map((item: TripDetails) => {
            const itemDate = item.date.split(' ');
            return +item.tripTime > (data?.average_time_per_trip ?? 0)
              ? `
        Trip ${item.tripNumber} on ${itemDate[0]} for ${
                  item.device
                } with trip time of ${convertSecondsWithUnitValues(
                  +item.tripTime
                )}
        `
              : null;
          })
          .filter((item) => item !== null)
      : 'None';

  const outlierTripsBasedOnTime =
    data.outlier_trips_based_on_time &&
    data.outlier_trips_based_on_time.length > 0
      ? data.outlier_trips_based_on_time
          .map((item: TripDetails) => {
            const itemDate = item.date.split(' ');
            return `
        Trip ${item.tripNumber} on ${itemDate[0]} for ${
              item.device
            } with trip time of ${convertSecondsWithUnitValues(+item.tripTime)}
        `;
          })
          .filter((item) => item !== null)
      : 'None';

  const averageDistance = data.average_distance_per_trip
    ? data.average_distance_per_trip.toFixed(2)
    : 'N/A';
  const totalDistance = data.total_distance
    ? data.total_distance.toFixed(2)
    : 'N/A';
  const totalPossibleDistanceSavings =
    data.total_possible_distance_savings_excluding_outliers_avg
      ? data.total_possible_distance_savings_excluding_outliers_avg.toFixed(2)
      : 'N/A';
  const tripsMoreThanAverageDistance =
    data.trips_more_than_average_distance &&
    data.trips_more_than_average_distance.length > 0
      ? data.trips_more_than_average_distance
          .map((item: TripDetails) => {
            const itemDate = item.date.split(' ');
            return +item.travelDistance > +averageDistance
              ? `
        Trip ${item.tripNumber} on ${itemDate[0]} for ${
                  item.device
                } covering distance of ${
                  item.travelDistance
                } ${getLabelWrtUnitSystem('KM', unitSystem).toLowerCase()}.
        `
              : null;
          })
          .filter((item) => item !== null)
      : 'None';

  return {
    totalTrips: data.total_trips ?? 0,
    totalTripTime: totalTimeInHours,
    possibleTimeSaved: totalPossibleTimeSavingsInHours,
    averageDistance,
    totalDistance,
    totalPossibleDistanceSavings,
    outlierTripsBasedOnTime,
    tripsMoreThanAverageTime,
    tripsMoreThanAverageDistance,
  };
}
