import { Base } from 'components/Containers/BaseContainer';
import {
  Box,
  Divider,
  type SelectChangeEvent,
  Typography,
  CardContent,
  Card,
  CardHeader,
  Grid,
} from '@mui/material';
import React, { useState, useEffect, type FC } from 'react';
import { MultiSelector } from 'components/Form/Selectors/MultiSelector';
import { type SelectOptions } from 'types/utils.types';
import {
  handleSelectAll,
  handleSelectSingle,
} from 'views/Settings/Rules/helpers/common';
import { Selector } from 'components/Form/Selectors/Selector';
import {
  AddToReports,
  TypographyWithIcon,
} from '../../../Helpers/squareHelper';
import { PieChartComponent } from '../../../Helpers/Graphs/Squares.pieChart';
import {
  filterAssetsByComponent,
  getCategoryValue,
  getUniqueComponents,
} from '../../Helper/helperFunction';
import { type PieOptions, type Assets } from '../../modals/assets.modals';
import Colors from '../../../Helpers/Graphs/styles';
import CustomRangeDatePicker, {
  type SelectedDates,
} from '../../../Helpers/customDatePicker';
import { ThemePalette } from 'mui.theme';

interface PmVsCmProps {
  fetchSquaresData: (
    selectedStartDate: Date,
    selectedEndDate: Date,
    selectedAssetIds: string[],
    category: string
  ) => Promise<PieOptions[]>;
  getAssets: Assets[];
  graphId: string;
  enableDarkTheme: boolean;
}

export const PmVsCm: FC<PmVsCmProps> = ({
  fetchSquaresData,
  getAssets,
  graphId,
  enableDarkTheme,
}) => {
  const [pieOption, setPieOption] = useState<PieOptions[]>([]);
  const [assetOptions, setAssetOptions] = useState<SelectOptions[]>([]);
  const [selectedAssets, setSelectedAssets] = useState<{
    assets: SelectOptions[];
  }>({ assets: [] });
  const [selectedAssetIds, setSelectedAssetIds] = useState<string[]>([]);
  const [selectedComponentCategory, setSelectedComponentCategory] =
    useState<string>('');
  const [componentOptions, setComponentOptions] = useState<Assets[]>([]);

  const [isLoading, setIsLoading] = useState(true);
  const [isNoData, setIsNoData] = useState<boolean>(false);

  const formerSelectedDates: any = sessionStorage.getItem(
    'datePickerSelection'
  );

  let initialStartDate = new Date();
  initialStartDate.setFullYear(initialStartDate.getFullYear() - 1);
  let initialEndDate = new Date();
  if (formerSelectedDates) {
    const dateObjs = JSON.parse(formerSelectedDates);
    initialStartDate = new Date(dateObjs.startDate);
    initialEndDate = new Date(dateObjs.endDate);
  }

  const [selectedDates, setSelectedDates] = useState<SelectedDates>({
    startDate: initialStartDate,
    endDate: initialEndDate,
  });

  const handleDateChange = ({
    startDate,
    endDate,
  }: {
    startDate: Date | null;
    endDate: Date | null;
  }) => {
    setSelectedDates({ startDate, endDate });
    sessionStorage.setItem(
      'datePickerSelection',
      JSON.stringify({
        startDate,
        endDate,
      })
    );
  };
  useEffect(() => {
    if (getAssets.toString() === null) {
      setIsNoData(true);
      setIsLoading(false);

      return;
    } else {
      setIsNoData(false);
    }
    setIsLoading(true); // Set isLoading state to true

    async function fetchData() {
      if (selectedAssetIds.length > 0) {
        if (
          selectedDates.startDate === null ||
          selectedDates.endDate === null
        ) {
          return;
        }
        const data = await fetchSquaresData(
          selectedDates.startDate,
          selectedDates.endDate,
          selectedAssetIds,
          selectedComponentCategory
        );
        setPieOption(data);
      }
      setIsLoading(false); // Set isLoading state to true
    }

    void fetchData();
  }, [selectedDates, selectedAssetIds, selectedComponentCategory]);

  useEffect(() => {
    if (getAssets.toString() === null) {
      setIsNoData(true);
      setIsLoading(false);

      return;
    } else {
      setIsNoData(false);
    }
    const newSelectedAssetIds = selectedAssets.assets.map((asset) => asset.id);
    setSelectedAssetIds(newSelectedAssetIds);
  }, [selectedAssets]);

  useEffect(() => {
    if (getAssets.toString() === null) {
      setIsNoData(true);
      setIsLoading(false);

      return;
    } else {
      setIsNoData(false);
    }
    setIsLoading(true); // Set isLoading state to true

    const updatedAssetOptions = getAssets;
    setAssetOptions(updatedAssetOptions);

    const updatedComponentOptions = getUniqueComponents(
      updatedAssetOptions
    ).map((component) => ({
      id: component,
      display: component,
      components: component,
    }));
    setComponentOptions(updatedComponentOptions);

    // Set default values
    if (updatedComponentOptions.length > 0) {
      const defaultComponent = updatedComponentOptions[0].id;
      setSelectedComponentCategory(defaultComponent);
    }

    if (updatedAssetOptions.length > 0) {
      const defaultAssets = updatedAssetOptions.slice(0, 1).map((asset) => ({
        id: asset.id,
        display: asset.display,
      }));
      setSelectedAssets({ assets: defaultAssets });
    }
  }, [getAssets]);

  const handleComponentCategoryChange = (event: SelectChangeEvent<any>) => {
    setSelectedComponentCategory(event.target.value);
  };

  return (
    <>
      <Base>
        <Box
          sx={{
            backgroundColor: enableDarkTheme
              ? ThemePalette.dark.boxBackground
              : ThemePalette.light.boxBackground,
            paddingTop: '10px',
            paddingLeft: '10px',
            paddingRight: '10px',
            fontSize: '10px',
          }}
        >
          <Grid
            container
            spacing={2}
            direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row' }} // Responsive direction
            justifyContent="start"
          >
            {/* Component Category Selector */}
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Typography
                sx={{
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                }}
              >
                Total{' '}
              </Typography>
              <Selector
                minWidth="100%"
                value={selectedComponentCategory}
                onChange={handleComponentCategoryChange}
                selectorOptions={componentOptions}
                enableDarkTheme={enableDarkTheme}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Typography
                sx={{
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                }}
              >
                Select Asset(s)
              </Typography>
              <MultiSelector
                dropdownHorizontalPosition="right"
                minWidth="100%"
                value={selectedAssets.assets}
                renderValue={(selected) => {
                  // Calculate the total number of options from selectorOptions
                  const totalOptions = filterAssetsByComponent(
                    selectedComponentCategory,
                    getAssets
                  ); // Assuming assetOptions is the array used as selectorOptions
                  console.log(assetOptions);
                  // Check if all items are selected
                  const isAllSelected =
                    selected?.length === assetOptions.length;

                  // Show total options count if all are selected, otherwise show selected count
                  return isAllSelected
                    ? `${totalOptions.length} Asset(s) Selected`
                    : `${selected?.length || 0} Asset(s) Selected`;
                }}
                handleSelectOne={(event) => {
                  handleSelectSingle(
                    event,
                    selectedAssets,
                    setSelectedAssets,
                    'assets'
                  );
                }}
                handleSelectAll={() => {
                  handleSelectAll(
                    assetOptions,
                    selectedAssets,
                    setSelectedAssets,
                    'assets'
                  );
                }}
                selectorOptions={filterAssetsByComponent(
                  selectedComponentCategory,
                  getAssets
                )}
                enableDarkTheme={enableDarkTheme}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Typography
                sx={{
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                }}
              >
                For Date Range:
              </Typography>

              <CustomRangeDatePicker
                selectedDates={selectedDates}
                handleDateChange={handleDateChange}
                enableDarkTheme={enableDarkTheme}
              />
            </Grid>
            <Grid
              item
              xs={12} // Full width on xs screens
              sm={12} // Full width on sm screens
              md={2} // Adjust width on md and larger
              lg={2} // Adjust width on lg screens
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              {' '}
              <AddToReports
                graphId={graphId}
                data={pieOption}
                selectedAssets={selectedAssets}
                interval={selectedDates}
                orientation="vertical"
              />
            </Grid>
          </Grid>

          <Divider variant="middle" sx={{ margin: '3px' }} />
          <div id={graphId} style={{ width: '100%', height: '100%' }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Grid item xs={2}>
                <PieChartComponent
                  header="Cost"
                  isLoading={isLoading}
                  isNoData={
                    getCategoryValue(
                      pieOption,
                      'PM',
                      'percentage_category_cost'
                    ) === null || isNoData
                  }
                  uniqueKey={getCategoryValue(pieOption, 'PM', 'category')}
                  dataPreventative={getCategoryValue(
                    pieOption,
                    'PM',
                    'percentage_category_cost'
                  )}
                  dataCorrective={getCategoryValue(
                    pieOption,
                    'CM',
                    'percentage_category_cost'
                  )}
                  enableDarkTheme={enableDarkTheme}
                />
              </Grid>
              <Grid item xs={2}>
                <PieChartComponent
                  header="Hours"
                  isLoading={isLoading}
                  isNoData={
                    getCategoryValue(
                      pieOption,
                      'PM',
                      'percentage_category_cost'
                    ) === null ||
                    isLoading ||
                    isNoData
                  }
                  uniqueKey={getCategoryValue(pieOption, 'CM', 'category')}
                  dataPreventative={getCategoryValue(
                    pieOption,
                    'PM',
                    'percentage_category_hours'
                  )}
                  dataCorrective={getCategoryValue(
                    pieOption,
                    'CM',
                    'percentage_category_hours'
                  )}
                  enableDarkTheme={enableDarkTheme}
                />
              </Grid>
              <Grid item xs={5} paddingTop={8}>
                <Card>
                  <CardHeader
                    title="Maintenance Type"
                    sx={{
                      backgroundColor: enableDarkTheme
                        ? ThemePalette.dark.menuOptions
                        : ThemePalette.light.menuOptions,
                      color: enableDarkTheme
                        ? ThemePalette.typography.white
                        : ThemePalette.typography.black,
                      '& .MuiCardHeader-title': {
                        fontSize: '1rem', // Smaller font size for the title
                      },
                    }}
                  />
                  <CardContent
                    sx={{
                      backgroundColor: enableDarkTheme
                        ? ThemePalette.dark.menuOptions
                        : ThemePalette.light.menuOptions,
                    }}
                  >
                    <TypographyWithIcon
                      text="Corrective Maintenance"
                      color={Colors.purple}
                      enableDarkTheme={enableDarkTheme}
                    />
                    <TypographyWithIcon
                      text="Preventative Maintenance"
                      color={Colors.yellow}
                      enableDarkTheme={enableDarkTheme}
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </div>
        </Box>
      </Base>
    </>
  );
};
