import convert from 'convert-units';
import { UNITS } from 'utils/enums';

// Function to convert liters per kilometer (l/km) to miles per gallon (mpg)
export function convertLitersPerKmToMpg(litersPerKm: string | number): number {
  const litersPerGallon = convert(1).from('l').to('gal');
  const kilometersPerMile = convert(1).from('km').to('mi');

  const gallonsPerKm = Number(litersPerKm) * litersPerGallon;
  const gallonsPerMile = gallonsPerKm / kilometersPerMile;

  const mpg = 1 / gallonsPerMile;
  return mpg;
}

export function convertMpgToLitersPerKm(mpg: number): number {
  const gallonsPerMile = 1 / mpg;
  const kilometersPerMile = convert(1).from('mi').to('km');
  const litersPerGallon = convert(1).from('gal').to('l');

  const gallonsPerKm = gallonsPerMile * kilometersPerMile;
  const litersPerKm = gallonsPerKm / litersPerGallon;

  return litersPerKm;
}

export function convertLitersToGallons(liters: string | number): number {
  return convert(Number(liters)).from('l').to('gal');
}

export function convertGallonsToLiters(gallons: number): number {
  return convert(Number(gallons)).from('gal').to('l');
}

export function convertLphToGpm(lph: string | number): number {
  return convert(Number(lph)).from('l/h').to('gal/min');
}

export function convertGpmToLph(gpm: number): number {
  return convert(Number(gpm)).from('gal/min').to('l/h');
}

export function convertKmsToMiles(kms: string | number): number {
  return convert(Number(kms)).from('km').to('mi');
}

export function convertMilesToKms(miles: number): number {
  return convert(Number(miles)).from('mi').to('km');
}

export function convertKgToLbs(kgs: number): number {
  return convert(Number(kgs)).from('kg').to('lb');
}

export function convertLbsToKg(lbs: number): number {
  return convert(Number(lbs)).from('lb').to('kg');
}

export function convertKgPerTonnePerKmToLbsPerTonnePerMile(
  KgPerKm: number
): number {
  return KgPerKm;
}

export function convertKgPerKmToLbsPerMile(KgPerKm: number): number {
  return KgPerKm;
}

export function convertLbsPerMileToKgPerKm(lbsPerMile: number): number {
  return lbsPerMile;
}

export function convertMetricTonsToImperialTons(metricTons: number): number {
  // Convert metric tons to pounds
  const pounds = convert(metricTons).from('t').to('lb');
  // Convert pounds to imperial tons (1 imperial ton = 2240 pounds)
  return pounds / 2240;
}

export function convertImperialTonsToMetricTons(imperialTons: number): number {
  const pounds = imperialTons * 2240;
  return convert(pounds).from('lb').to('t');
}

export function convertBarToPsi(bar: number): number {
  return convert(bar).from('bar').to('psi');
}

export function convertPsiToBar(psi: number): number {
  return convert(psi).from('psi').to('bar');
}

export function convertMs2ToFtS2(ms: number): number {
  const ms2 = 'm/s2' as any;
  const fts2 = 'ft/s2' as any;
  return convert(ms).from(ms2).to(fts2);
}

export function convertFtS2ToMs2(ftS2: number): number {
  const ms2 = 'm/s2' as any;
  const fts2 = 'ft/s2' as any;
  return convert(ftS2).from(fts2).to(ms2);
}

export function convertKpaToPsi(kPa: number): number {
  return convert(kPa).from('kPa').to('psi');
}

export function convertPsiToKpa(psi: number): number {
  return convert(psi).from('psi').to('kPa');
}

export function convertCentToFahr(cent: number): number {
  return convert(cent).from('C').to('F');
}

export function convertFahrToCent(fahr: number): number {
  return convert(fahr).from('F').to('C');
}

export function convertBasedOnUnitSystem(
  convertMethod: (value: number) => number,
  value: number | string,
  unitSystem: string,
  trimDecimal = 2
): number {
  const convertedValue =
    unitSystem === 'metric' ? Number(value) : convertMethod(Number(value));
  // return typeof value === 'string' ? String(convertedValue) : convertedValue;
  return Number(convertedValue.toFixed(trimDecimal));
}

export function getLabelWrtUnitSystem(
  label: string,
  unitSystem: string
): string {
  const updatedLabel =
    unitSystem !== 'metric'
      ? UNITS.find(
          (unitObj) => unitObj.id === label || unitObj?.imperial === label
        )?.imperial ?? ''
      : UNITS.find((unitObj) => unitObj.id === label)?.display ?? '';
  return updatedLabel;
}

// export function convertToMetricSystem(
//   convertMethod: (value: number) => number,
//   value: number | string,
//   unitSystem: string,
//   trimDecimal = 2,
// ): number {
//   const convertedValue =
//     unitSystem === 'imperial' ? Number(value) : convertMethod(Number(value));
//   // return typeof value === 'string' ? String(convertedValue) : convertedValue;
//   return Number(convertedValue.toFixed(trimDecimal));
// }
