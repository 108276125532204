import BaseAPI from './base.api';
import { getPastDate } from './utils';

export default class UtilizationAPI extends BaseAPI {
  getTotalFleet = async (
    range: string,
    startDate: string,
    endDate: string,
    shouldSendDate: boolean
  ) => {
    let params: any = {
      range,
    };
    if (shouldSendDate) {
      params = {
        startDate,
        endDate,
      };
    }
    const response = await this.API.get('utilization/total-fleet', {
      params: {
        ...params,
      },
    });
    return response.data;
  };

  getTotalFleetSummary = async (
    range: string,
    startDate: string,
    endDate: string,
    shouldSendDate: boolean
  ) => {
    let params: any = {
      range,
    };
    if (shouldSendDate) {
      params = {
        startDate,
        endDate,
      };
    }
    const response = await this.API.get('utilization/date-range-summary', {
      params: {
        ...params,
      },
    });
    return response.data;
  };

  getTotalFleetUtilization = async () => {
    const response = await this.API.get('utilization/ytd-summary');
    return response.data;
  };

  getFleetPeriodSummary = async () => {
    const response = await this.API.get('utilization/fleet-period-summary');
    return response.data;
  };

  getSuggestions = async (
    range: string,
    startDate: string,
    shouldSendDate: boolean,
    assetType: string
  ) => {
    let dateToUse;
    if (shouldSendDate) {
      dateToUse = startDate;
    } else {
      const rangeDate = getPastDate(range);
      dateToUse = rangeDate;
    }

    const response = await this.API.get('utilization/suggestions', {
      params: {
        startDate: dateToUse,
        assetType,
      },
    });
    return response.data;
  };
}
