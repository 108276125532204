import CustomerAPI from 'api/customer.api';
import { ExternalUserAPI } from 'api/external.user.api';
import { updateLoadingState } from 'store/app.slice';
import { updateCustomer, updateCustomerTheme } from 'store/auth.slice';
import {
  appendExternalUserList,
  setExternalUserList,
  updateExternalUserList,
} from 'store/external.user.slice';
import { type IUpdateCustomerPayload } from 'types/payloads/customer.payload.types';
import {
  type ICreateExternalUserPayload,
  type IUpdateExternalUserPayload,
} from 'types/payloads/user.payload.types';
import { type ExternalUser } from 'types/user.types';
import { BaseHandler } from './base.handler';

export default class ExternalUserHandler extends BaseHandler {
  private readonly API: ExternalUserAPI = new ExternalUserAPI();
  private readonly CustomerAPI: CustomerAPI = new CustomerAPI();

  async createExternalUser(payload: ICreateExternalUserPayload): Promise<void> {
    this.dispatch(updateLoadingState(true));

    try {
      const newUser = await this.API.create(payload);

      this.dispatch(appendExternalUserList(newUser));

      this.handleSuccess(
        'A new user has been created and a password creation email has been sent.'
      );
    } catch (_) {
      // show snackbar error
      this.handleError('An error occurred while creating the user.');
    }
  }

  async getAll(): Promise<ExternalUser[] | []> {
    this.dispatch(updateLoadingState(true));

    try {
      const userList = await this.API.getAll();

      this.dispatch(setExternalUserList(userList));

      this.dispatch(updateLoadingState(false));

      return userList;
    } catch (_) {
      this.dispatch(updateLoadingState(false));
    }

    return [];
  }

  async updateExternalUser(
    userId: string,
    payload: IUpdateExternalUserPayload
  ): Promise<void> {
    this.dispatch(updateLoadingState(true));

    try {
      const updatedUser = await this.API.update({
        ...payload,
        userId,
      });

      this.dispatch(updateExternalUserList(updatedUser));

      this.handleSuccess('User has been updated successfully.');
    } catch (_) {
      this.handleError('An error occurred while updating the user.');
    }
  }

  async deactivateExternalUser(
    userId: string,
    firstName: string,
    lastName: string
  ): Promise<void> {
    this.dispatch(updateLoadingState(true));

    try {
      const updatedUser = await this.API.toggleActiveExternalUser(
        userId,
        false
      );

      this.dispatch(updateExternalUserList(updatedUser));

      this.handleSuccess(`${firstName} ${lastName} has been deactivated.`);
    } catch (_) {
      // show snackbar error
      this.handleError('An error occurred while deactivating the user.');
    }
  }

  async activateExternalUser(
    userId: string,
    firstName: string,
    lastName: string
  ): Promise<void> {
    this.dispatch(updateLoadingState(true));

    try {
      const updatedUser = await this.API.toggleActiveExternalUser(userId, true);

      this.dispatch(updateExternalUserList(updatedUser));

      this.handleSuccess(`${firstName} ${lastName} has been activated.`);
    } catch (_) {
      // show snackbar error
      this.handleError('An error occurred while activating the user.');
    }
  }

  async currentCustomer(): Promise<any> {
    this.dispatch(updateLoadingState(true));

    try {
      // Get the customer from the DB
      const customer = await this.API.currentCustomer();

      // Save the information to redux
      this.dispatch(
        updateCustomer({
          id: customer.customerId,
          name: customer.customerName,
          code: customer.customerCode,
          contactFirstName: customer.contactFirstName,
          contactLastName: customer.contactLastName,
          email: customer.email,
          phoneNumber: customer.phoneNumber,
          internalAccountingLink: customer.internalAccountingLink,
          unitSystem: customer.unitSystem,
          theme: customer.theme,
        })
      );
    } catch (_) {
      // show snackbar error
      this.handleError('An error occurred while receiving customer.');
    }
  }

  /**
   * Update the current customer of the user
   */
  async updateCurrentCustomer(payload: IUpdateCustomerPayload) {
    this.dispatch(updateLoadingState(true));

    try {
      // Update the information
      const customer = await this.CustomerAPI.updateCustomer(payload);

      // Save the information
      this.dispatch(
        updateCustomer({
          id: customer.customerId,
          name: customer.customerName,
          code: customer.customerCode,
          contactFirstName: customer.contactFirstName,
          contactLastName: customer.contactLastName,
          email: customer.email,
          phoneNumber: customer.phoneNumber,
          internalAccountingLink: customer.internalAccountingLink,
          unitSystem: customer.unitSystem,
          theme: customer.theme,
        })
      );

      this.handleSuccess('Customer information has been updated');
    } catch (_) {
      this.handleError('An error occured while updating customer information.');
    }
  }

  async updateCurrentCustomerTheme(payload: {
    theme: string;
    customerId: string;
  }) {
    this.dispatch(updateLoadingState(true));

    try {
      await this.CustomerAPI.updateCustomerTheme(payload);
      this.dispatch(updateCustomerTheme(payload));
      this.handleSuccess('Theme has been updated');
    } catch (_) {
      this.handleError(
        'An error occured while updating customer theme selection.'
      );
    }
  }
}
