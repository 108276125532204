import UtilizationAPI from 'api/utilization.api';
import {
  setFleetSummary,
  setIsLoading,
  setFleetPeriodSummary,
  setTotalFleetAssets,
  setFleetDetails,
} from 'store/utilization.slice';

import { BaseHandler } from './base.handler';

export default class UtilizationHandler extends BaseHandler {
  private readonly api: UtilizationAPI;

  constructor() {
    super();

    this.api = new UtilizationAPI();
  }

  async get(
    range: string,
    startDate: string,
    endDate: string,
    shouldSendDate: boolean,
    initialRun: boolean,
    assetType: string
  ): Promise<any> {
    let stateChanges: any = {
      isLoadingFleetAssets: true,
      isLoadingFleetSummary: true,
      isLoadingSuggestions: true,
    };

    if (initialRun) {
      stateChanges = {
        ...stateChanges,
        isLoadingFleetDetails: true,
        isLoadingFleetPeriodSummary: true,
      };
    }

    this.dispatch(setIsLoading(stateChanges));

    try {
      const fleetSummaryPromise = this.api.getTotalFleetSummary(
        range,
        startDate,
        endDate,
        shouldSendDate
      );

      const fleetAssetsPromise = this.api.getTotalFleet(
        range,
        startDate,
        endDate,
        shouldSendDate
      );

      let fleetDetailsPromise, fleetPeriodPromise;
      if (initialRun) {
        fleetPeriodPromise = this.api.getFleetPeriodSummary();
        fleetDetailsPromise = this.api.getTotalFleetUtilization();
      }

      if (initialRun) {
        fleetDetailsPromise
          ?.then((totalFleetDetails) => {
            this.dispatch(setFleetDetails(totalFleetDetails.data));
            this.dispatch(
              setIsLoading({
                isLoadingFleetDetails: false,
              })
            );
          })
          .catch(() => {
            this.handleError('Error fetching fleet details');
            this.dispatch(
              setIsLoading({
                isLoadingFleetDetails: false,
              })
            );
          });

        fleetPeriodPromise
          ?.then((fleetPeriod) => {
            this.dispatch(setFleetPeriodSummary(fleetPeriod.data));
            this.dispatch(
              setIsLoading({
                isLoadingFleetPeriodSummary: false,
              })
            );
          })
          .catch(() => {
            this.handleError('Error fetching fleet period summary');
            this.dispatch(
              setIsLoading({
                isLoadingFleetPeriodSummary: false,
              })
            );
          });
      }

      fleetSummaryPromise
        .then((fleetSummary) => {
          this.dispatch(setFleetSummary(fleetSummary));
          this.dispatch(
            setIsLoading({
              isLoadingFleetSummary: false,
            })
          );
        })
        .catch(() => {
          this.handleError('Error fetching fleet summary');
          this.dispatch(
            setIsLoading({
              isLoadingFleetSummary: false,
            })
          );
        });

      fleetAssetsPromise
        .then((fleetAssets) => {
          this.dispatch(setTotalFleetAssets(fleetAssets.assets));
          this.dispatch(
            setIsLoading({
              isLoadingFleetAssets: false,
            })
          );
          return [];
        })
        .catch(() => {
          this.handleError('Error fetching fleet assets');
          this.dispatch(
            setIsLoading({
              isLoadingFleetAssets: false,
            })
          );
          return {};
        });

      return [];
    } catch (_) {
      this.handleError('An error occurred while fetching utilizaiton details');
    }
    return {};
  }
}
