import { Button, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ThemePalette } from 'mui.theme';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import MultiColorCircularLoader from 'views/Production/components/BoxLoader/MultiColorCircularLoader';
import CircularProgressChart from 'views/Production/components/CircularProgress/CircularProgress';
import WarningIcon from '@mui/icons-material/Warning';
import { convertToTwoDecimalPlaces } from 'components/Charts/Tooltip';
import type { totalFleetDetails } from 'types/production.types';
import { percentageLeft, formatNumberToShortScale } from './utils';

interface Props {
  enableDarkTheme: boolean;
  isLoading: boolean;
  unitSystem: string;
  data: totalFleetDetails;
}

const HaulerProductionDetails = ({
  enableDarkTheme,
  isLoading,
  unitSystem,
  data,
}: Props) => {
  const hasMetTarget = data.haulerTon > 0;
  const targetPercentageDiff = percentageLeft(
    data.haulerTon,
    data.annualTarget
  );
  return (
    <Grid item lg={6} md={6} sm={12}>
      <Box
        sx={{
          padding: 2,
          backgroundColor: enableDarkTheme
            ? ThemePalette.dark.boxBackground
            : ThemePalette.light.toolBarBackground,
          borderRadius: '5px',
          border: '1px solid #2F445D',
          minHeight: '300px',
          maxHeight: '300px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                fontWeight: '600',
                fontSize: '13px',
              }}
            >
              Production Per Hauler
            </Typography>
            {/* <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              <Typography
                sx={{
                  fontSize: '12px',
                }}
              >
                Mine Production Plan:
              </Typography>
              <Button
                size="small"
                sx={{
                  border: '1px solid #4A7BFA',
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                  textTransform: 'none',
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  fontSize: '11px',
                }}
                startIcon={<FileUploadIcon />}
              >
                Upload
              </Button>
            </Box> */}
          </Box>
        </Box>

        {isLoading ? (
          <MultiColorCircularLoader height="300px" />
        ) : (
          <Box
            sx={{
              paddingTop: '15px',
            }}
          >
            <Grid container>
              <Grid item lg={6} sm={12}>
                <Box
                  sx={{
                    display: 'flex',
                  }}
                >
                  <CircularProgressChart
                    enableDarkTheme={enableDarkTheme}
                    value={data.haulerTon}
                    target={data.ytdTarget}
                    unitSystem={unitSystem}
                  />
                  <Box
                    sx={{
                      alignContent: 'center',
                      paddingLeft: '10px',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '11px',
                        color: enableDarkTheme
                          ? ThemePalette.typography.lightGrey
                          : ThemePalette.typography.black,
                      }}
                    >
                      YTD Target
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '11px',
                        color: enableDarkTheme
                          ? ThemePalette.typography.white
                          : ThemePalette.typography.black,
                        paddingBottom: '15px',
                      }}
                    >
                      {formatNumberToShortScale(
                        convertToTwoDecimalPlaces(data.ytdTarget.toString())
                      )}{' '}
                      {unitSystem === 'imperial' ? 'iTons' : 'Tons'}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '11px',
                        color: enableDarkTheme
                          ? ThemePalette.typography.lightGrey
                          : ThemePalette.typography.black,
                      }}
                    >
                      Annual Target
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '11px',
                        color: enableDarkTheme
                          ? ThemePalette.typography.white
                          : ThemePalette.typography.black,
                      }}
                    >
                      {formatNumberToShortScale(
                        convertToTwoDecimalPlaces(data.annualTarget.toString())
                      )}{' '}
                      {unitSystem === 'imperial' ? 'iTons' : 'Tons'}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item lg={6} sm={12}>
                <Box>
                  <Grid container>
                    <Grid item lg={6} sm={12}>
                      <Typography
                        sx={{
                          fontSize: '12px',
                          color: enableDarkTheme
                            ? ThemePalette.typography.lightGrey
                            : ThemePalette.typography.black,
                        }}
                      >
                        Total Vehicles
                      </Typography>

                      <Typography
                        sx={{
                          fontSize: '12px',
                          color: enableDarkTheme
                            ? ThemePalette.typography.white
                            : ThemePalette.typography.black,
                          paddingTop: '10px',
                        }}
                      >
                        {data.haulerCount}
                      </Typography>

                      <Typography
                        sx={{
                          paddingTop: '30px',
                          fontSize: '12px',
                          color: enableDarkTheme
                            ? ThemePalette.typography.lightGrey
                            : ThemePalette.typography.black,
                        }}
                      >
                        Annual Production Average
                      </Typography>

                      <Typography
                        sx={{
                          fontSize: '13px',
                          color: enableDarkTheme
                            ? ThemePalette.typography.white
                            : ThemePalette.typography.black,
                          paddingTop: '10px',
                        }}
                      >
                        {formatNumberToShortScale(
                          convertToTwoDecimalPlaces(
                            data.annualTarget.toString()
                          )
                        )}
                        <span
                          style={{
                            color: enableDarkTheme
                              ? ThemePalette.typography.lightGrey
                              : ThemePalette.typography.black,
                          }}
                        >
                          {' '}
                          {unitSystem === 'imperial' ? 'iTons' : 'Tons'}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item lg={6} sm={12}>
                      <Typography
                        sx={{
                          fontSize: '12px',
                          color: enableDarkTheme
                            ? ThemePalette.typography.lightGrey
                            : ThemePalette.typography.black,
                        }}
                      >
                        Total Production
                      </Typography>

                      <Typography
                        sx={{
                          fontSize: '13px',
                          color: enableDarkTheme
                            ? ThemePalette.typography.white
                            : ThemePalette.typography.black,
                          paddingTop: '10px',
                        }}
                      >
                        {formatNumberToShortScale(
                          convertToTwoDecimalPlaces(data.haulerTon.toString())
                        )}
                        <span
                          style={{
                            color: enableDarkTheme
                              ? ThemePalette.typography.lightGrey
                              : ThemePalette.typography.black,
                          }}
                        >
                          {' '}
                          {unitSystem === 'imperial' ? 'iTons' : 'Tons'}
                        </span>
                      </Typography>
                    </Grid>
                  </Grid>

                  {hasMetTarget ? (
                    <></>
                  ) : (
                    <Box
                      sx={{
                        textAlign: 'center',
                        border: '1px solid #2F445F',
                        borderRadius: '5px',
                        marginTop: '15px',
                        padding: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '12px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <WarningIcon
                          sx={{ color: '#FFB402', marginRight: '4px' }}
                        />
                        Avg Production needs to go up by
                        <span
                          style={{
                            color: '#FFB402',
                            marginLeft: '4px',
                          }}
                        >
                          {targetPercentageDiff}%
                        </span>
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    </Grid>
  );
};

export default HaulerProductionDetails;
