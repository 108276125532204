import { ButtonGroup, Button, Typography } from '@mui/material';
import { ThemePalette } from 'mui.theme';
import DatePicker from 'react-datepicker';
import './styles.css';

interface Props {
  enableDarkTheme: boolean;
  ignoreYtd?: boolean;
  isSelected: string;
  setIsSelected: any;
  handleDateChange: any;
  startDate: any;
  endDate: any;
  yesterday: any;
}

const DateRangeDiv = ({
  enableDarkTheme,
  ignoreYtd = false,
  isSelected,
  setIsSelected,
  handleDateChange,
  startDate,
  endDate,
  yesterday,
}: Props) => {
  const handleSelect = (id: string) => {
    setIsSelected(id);
  };

  let dateRangeList = ['1D', '7D', '14D', '1M', '1Y', 'YTD', 'Custom'];
  if (ignoreYtd) {
    dateRangeList = dateRangeList.filter((item) => item !== 'YTD');
  }
  return (
    <>
      <Typography
        style={{ fontFamily: "'Inter Variable', sans-serif", margin: '20px 0' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              color: enableDarkTheme
                ? ThemePalette.typography.mediumGray
                : '#3d3d3d',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Typography style={{ fontSize: '12px', marginRight: '10px' }}>
              Quick Range:
            </Typography>
            <ButtonGroup
              size="small"
              variant="outlined"
              aria-label="Basic button group"
              sx={{
                border: '1px solid #2F445D',
              }}
            >
              {dateRangeList.map((item) => {
                return (
                  <Button
                    key={item}
                    size="small"
                    style={{
                      backgroundColor:
                        isSelected === item
                          ? '#3666EC'
                          : enableDarkTheme
                          ? ThemePalette.dark.boxBackground
                          : ThemePalette.light.toolBarBackground,
                      color: enableDarkTheme
                        ? ThemePalette.typography.white
                        : ThemePalette.typography.black,
                    }}
                    onClick={() => {
                      handleSelect(item);
                    }}
                  >
                    {item}
                  </Button>
                );
              })}
            </ButtonGroup>
          </div>

          <div
            style={{
              color: enableDarkTheme
                ? ThemePalette.typography.mediumGray
                : '#3d3d3d',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Typography style={{ fontSize: '12px', margin: '0 10px 0 50px' }}>
              Date Range:
            </Typography>
            <DatePicker
              showIcon
              selected={startDate}
              startDate={startDate}
              endDate={endDate}
              onChange={handleDateChange}
              maxDate={yesterday}
              selectsRange
              className={
                enableDarkTheme
                  ? 'custom-datepicker-prod'
                  : 'custom-datepicker-prod-light-theme'
              }
              disabled={isSelected !== 'Custom'}
            />
          </div>
        </div>
      </Typography>
    </>
  );
};

export default DateRangeDiv;
